import React, { useState, useEffect } from 'react';
import { HiChevronDown, HiSquares2X2 } from "react-icons/hi2";
import { getCommunityGroups, getUserAdminCommunities } from '../../services/dashboardService';

import { useNavigate } from 'react-router-dom';

const AdminNav = ({ userId }) => {
  const [adminCommunities, setAdminCommunities] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAdminCommunities = async () => {
      try {
        const communities = await getUserAdminCommunities(userId);
        setAdminCommunities(communities);
      } catch (error) {
        console.error('Error fetching admin communities:', error);
      }
    };

    if (userId) {
      fetchAdminCommunities();
    }
  }, [userId]);

  if (!adminCommunities.length) return null;

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="inline-flex items-center px-4 py-2 text-sm font-medium bg-gray-50 text-gray-700 rounded-lg hover:bg-gray-100 border border-gray-200 transition-colors"
      >
        <HiSquares2X2 className="w-4 h-4 mr-2" />
        Admin Dashboard
        <HiChevronDown className={`w-4 h-4 ml-2 transform transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-2 w-56 bg-white rounded-md shadow-lg z-50">
          <div className="py-1">
            {adminCommunities.map((community) => (
              <button
                key={community.id}
                onClick={() => {
                  navigate(`/admin/${community.id}`);
                  setIsOpen(false);
                }}
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                {community.name}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminNav;