import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';

// Import pages
import AdminDashboard from './pages/AdminDashboard/AdminDashboard';
import Authenticate from './pages/Authenticate/Authenticate';
import Home from './pages/Home/Home';
import Trivia from './pages/Trivia/Trivia';
import UserDashboard from './pages/UserDashboard/UserDashboard';
import InviteHandler from './components/InviteHandler/InviteHandler';

function App() {
  return (
    <Router>
      <div className="app">
        <Routes>
          {/* Home route - handles both "/" and "/home" */}
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/authenticate" element={<Authenticate />} />
          <Route path="/invite" element={<InviteHandler />} />
          <Route path="/trivia" element={<Trivia />} />
          <Route path="/dashboard" element={<UserDashboard />} />
          <Route path="/dashboard/:communityId" element={<UserDashboard />} />
          <Route path="/admin/:communityId" element={<AdminDashboard />} />
          <Route path="*" element={<Navigate to="/" replace />} />

        </Routes>
      </div>
    </Router>
  );
}

export default App;