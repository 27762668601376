// StatsPage.jsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { 
  HiChartBar,
  HiUsers,
  HiAcademicCap,
  HiChevronRight,
  HiArrowTrendingUp,
  HiUserCircle,
  HiPuzzlePiece,
  HiCheckCircle,
  HiXCircle,  // Add this
  HiClock
} from "react-icons/hi2";
import {
  getGroupAggregateStats,
  getGroupParticipants,
  getUserDetailedStats,
  formatScore,
  getScoreColorClass,
  formatDifficulty
} from '../../services/statsService';

const StatsPage = () => {
  const { groupId } = useParams();
  const { userId } = useSelector((state) => state.user);
  
  // States
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [groupStats, setGroupStats] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userStats, setUserStats] = useState(null);
  const [activeView, setActiveView] = useState('overview'); // 'overview' or 'participant'

  useEffect(() => {

    const fetchData = async () => {
      try {
        setLoading(true);
        const [statsData, participantsData] = await Promise.all([
          getGroupAggregateStats(groupId),
          getGroupParticipants(groupId)
        ]);

        setGroupStats(statsData);
        setParticipants(participantsData);
      } catch (err) {
        setError('Failed to fetch statistics');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [groupId]);

  const handleUserSelect = async (user) => {
    try {
      setLoading(true);
      const userStatsData = await getUserDetailedStats(groupId, user.user_id);
      setUserStats(userStatsData);
      setSelectedUser(user);
      setActiveView('participant');
    } catch (err) {
      setError('Failed to fetch user statistics');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const GroupOverview = () => (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {/* Overall Stats Cards */}
        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center text-blue-600 mb-2">
            <HiUsers className="w-5 h-5 mr-2" />
            <h3 className="font-semibold">Participants</h3>
          </div>
          <p className="text-3xl font-bold">{groupStats?.overview.total_participants}</p>
          <p className="text-gray-600 text-sm mt-1">
            {groupStats?.overview.users_completed} completed
          </p>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center text-green-600 mb-2">
            <HiChartBar className="w-5 h-5 mr-2" />
            <h3 className="font-semibold">Average Score</h3>
          </div>
          <p className={`text-3xl font-bold ${getScoreColorClass(groupStats?.overview.average_score_percentage)}`}>
            {formatScore(groupStats?.overview.average_score_percentage)}
          </p>
          <p className="text-gray-600 text-sm mt-1">
            Group average
          </p>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center text-purple-600 mb-2">
            <HiCheckCircle className="w-5 h-5 mr-2" />
            <h3 className="font-semibold">Completion Rate</h3>
          </div>
          <p className="text-3xl font-bold text-purple-600">
            {formatScore(groupStats?.overview.completion_rate)}
          </p>
          <p className="text-gray-600 text-sm mt-1">
            Of participants
          </p>
        </div>

        <div className="bg-white rounded-lg shadow p-6">
          <div className="flex items-center text-orange-600 mb-2">
            <HiArrowTrendingUp className="w-5 h-5 mr-2" />
            <h3 className="font-semibold">Progress</h3>
          </div>
          <p className="text-3xl font-bold text-orange-600">
            {Object.keys(groupStats?.difficulty_progression || {}).length} Levels
          </p>
          <p className="text-gray-600 text-sm mt-1">
            Difficulty progression
          </p>
        </div>
      </div>

      {/* Topic Performance */}
      <div className="bg-white rounded-lg shadow p-6">
        <h3 className="text-lg font-semibold mb-4">Topic Performance</h3>
        <div className="space-y-4">
          {groupStats?.topics && Object.entries(groupStats.topics).map(([topic, data]) => (
            <div key={topic} className="border-b pb-4">
              <div className="flex justify-between items-center mb-2">
                <span className="font-medium">{topic}</span>
                <span className={`${getScoreColorClass(data.avg_score * 10)} font-semibold`}>
                  {formatScore(data.avg_score * 10)}
                </span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div 
                  className="bg-blue-600 h-2.5 rounded-full"
                  style={{ width: `${data.avg_score * 10}%` }}
                />
              </div>
              <div className="flex justify-between text-sm text-gray-600 mt-1">
                <span>{data.users_attempted} participants</span>
                <span>{data.total_questions} questions</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Difficulty Progression */}
      <div className="bg-white rounded-lg shadow p-6">
        <h3 className="text-lg font-semibold mb-4">Difficulty Progression</h3>
        <div className="space-y-4">
          {groupStats?.difficulty_progression && 
            Object.entries(groupStats.difficulty_progression)
              .sort((a, b) => a[0].localeCompare(b[0]))
              .map(([level, data]) => (
                <div key={level} className="border-b pb-4">
                  <div className="flex justify-between items-center mb-2">
                    <span className="font-medium">{formatDifficulty(level)}</span>
                    <span className="text-gray-600">
                      {data.count} participants ({formatScore(data.percentage)})
                    </span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2.5">
                    <div 
                      className="bg-purple-600 h-2.5 rounded-full"
                      style={{ width: `${data.percentage}%` }}
                    />
                  </div>
                </div>
          ))}
        </div>
      </div>

      {/* Participants List */}
      <div className="bg-white rounded-lg shadow p-6">
        <h3 className="text-lg font-semibold mb-4">Participants</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Progress
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Score
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Level
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {participants.map((participant) => (
                <tr 
                  key={participant.user_id}
                  className="hover:bg-gray-50 cursor-pointer"
                  onClick={() => handleUserSelect(participant)}
                >
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 h-10 w-10">
                        <HiUserCircle className="h-10 w-10 text-gray-400" />
                      </div>
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {participant.name}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <span className="text-sm text-gray-900">
                        {participant.questions_answered} questions
                      </span>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`${getScoreColorClass(participant.average_score * 10)} text-sm font-semibold`}>
                      {formatScore(participant.average_score * 10)}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {formatDifficulty(participant.max_difficulty)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <button className="text-indigo-600 hover:text-indigo-900">
                      View Details <HiChevronRight className="inline-block w-4 h-4" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );




  const ParticipantDetail = () => {
    if (!userStats) return null;

    return (
      <div className="space-y-6">
        {/* Header */}
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <HiUserCircle className="h-12 w-12 text-gray-400" />
            <div>
              <h2 className="text-2xl font-bold">{userStats.name}</h2>
              <p className="text-gray-600">Individual Performance</p>
            </div>
          </div>
          <button
            onClick={() => setActiveView('overview')}
            className="text-gray-600 hover:text-gray-900 flex items-center"
          >
            Back to Overview
          </button>
        </div>

        {/* Stats Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex items-center text-blue-600 mb-2">
              <HiCheckCircle className="w-5 h-5 mr-2" />
              <h3 className="font-semibold">Completion</h3>
            </div>
            <p className="text-3xl font-bold">
              {formatScore(userStats.overview.completion_percentage)}
            </p>
            <p className="text-gray-600 text-sm mt-1">
              {userStats.overview.questions_answered} questions answered
            </p>
          </div>

          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex items-center text-green-600 mb-2">
              <HiChartBar className="w-5 h-5 mr-2" />
              <h3 className="font-semibold">Total Score</h3>
            </div>
            <p className={`text-3xl font-bold ${getScoreColorClass(userStats.overview.score_percentage)}`}>
              {formatScore(userStats.overview.score_percentage)}
            </p>
            <p className="text-gray-600 text-sm mt-1">
              {userStats.overview.total_score} points
            </p>
          </div>

          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex items-center text-purple-600 mb-2">
              <HiAcademicCap className="w-5 h-5 mr-2" />
              <h3 className="font-semibold">Average Score</h3>
            </div>
            <p className={`text-3xl font-bold ${getScoreColorClass(userStats.overview.average_score * 10)}`}>
              {formatScore(userStats.overview.average_score * 10)}
            </p>
            <p className="text-gray-600 text-sm mt-1">
              Per question
            </p>
          </div>

          <div className="bg-white rounded-lg shadow p-6">
            <div className="flex items-center text-orange-600 mb-2">
              <HiArrowTrendingUp className="w-5 h-5 mr-2" />
              <h3 className="font-semibold">Max Level</h3>
            </div>
            <p className="text-3xl font-bold text-orange-600">
              {formatDifficulty(userStats.overview.max_difficulty_reached)}
            </p>
            <p className="text-gray-600 text-sm mt-1">
              Highest difficulty
            </p>
          </div>
        </div>

        {/* Topic Performance */}
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-semibold mb-4">Topic Performance</h3>
          <div className="space-y-4">
            {userStats.topic_breakdown && Object.entries(userStats.topic_breakdown).map(([topic, data]) => (
              <div key={topic} className="border-b pb-4">
                <div className="flex justify-between items-center mb-2">
                  <span className="font-medium">{topic}</span>
                  <span className={`${getScoreColorClass(data.score_percentage)} font-semibold`}>
                    {formatScore(data.score_percentage)}
                  </span>
                </div>
                <div className="w-full bg-gray-200 rounded-full h-2.5">
                  <div 
                    className="bg-blue-600 h-2.5 rounded-full"
                    style={{ width: `${data.score_percentage}%` }}
                  />
                </div>
                <div className="flex justify-between text-sm text-gray-600 mt-1">
                  <span>{data.questions_answered} questions</span>
                  <span>Max Level: {formatDifficulty(data.max_difficulty)}</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Question Details */}
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-semibold mb-4">Question Details</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    #
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Question
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Response
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Score
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Topic
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Difficulty
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {userStats.question_details.map((question) => (
                  <tr key={question.question_number}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {question.question_number}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-900">
                      {question.question_text}
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-900">
                      <div className="flex items-center">
                        {question.is_correct ? (
                          <HiCheckCircle className="w-5 h-5 text-green-500 mr-2" />
                        ) : (
                          <HiXCircle className="w-5 h-5 text-red-500 mr-2" />
                        )}
                        {question.user_response}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className={`${getScoreColorClass(question.score * 10)} text-sm font-semibold`}>
                        {question.score}/10
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {question.topic}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatDifficulty(question.difficulty)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-red-600">{error}</div>
      </div>
    );
  }

  return (
    <div className="p-6 max-w-7xl mx-auto">
      {activeView === 'overview' ? <GroupOverview /> : <ParticipantDetail />}
    </div>
  );
};

export default StatsPage;
