import React, { useState } from 'react';

const SurveyConfig = ({ newGroup, setNewGroup, setCurrentView, handleCreateGroup, handleUpdateGroup, selectedGroup }) => {

  const [newTopic, setNewTopic] = useState('');
  const defaultTopics = ['Gospel Teachings', 'Catechism', 'Works of the Saints', 'Liturgy'];

  const handleAddTopic = (e) => {
    e.preventDefault();
    if (newTopic.trim() && !newGroup.topics.includes(newTopic.trim())) {
      setNewGroup(prevState => ({
        ...prevState,
        topics: [...prevState.topics, newTopic.trim()]
      }));
      setNewTopic('');
    }
  };

  const handleRemoveTopic = (topicToRemove) => {
    setNewGroup(prevState => ({
      ...prevState,
      topics: prevState.topics.filter(topic => topic !== topicToRemove)
    }));
  };

  const recommendedQuestions = newGroup.topics.length * 5;
  const estimatedTime = newGroup.questionCount * 30;

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 w-full">
      <div className="mb-6">
        <h2 className="text-xl font-bold">Assessment Configuration</h2>
        <p className="text-gray-600">Configure your faith assessment parameters</p>
      </div>

      <div className="space-y-6">
        {/* Assessment Name */}
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">Assessment Name</label>
          <input
            type="text"
            value={newGroup.name}
            onChange={(e) => setNewGroup(prevState => ({
              ...prevState,
              name: e.target.value
            }))}
            placeholder="Enter assessment name"
            className="w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Topics */}
        <div className="space-y-4">
          <label className="block text-sm font-medium text-gray-700">Topics</label>
          
          {/* Add new topic */}
          <div className="flex gap-2">
            <input
              type="text"
              value={newTopic}
              onChange={(e) => setNewTopic(e.target.value)}
              placeholder="Enter new topic"
              className="flex-1 rounded-md border border-gray-300 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              onClick={handleAddTopic}
              className="px-4 py-2 bg-blue-600 text-white rounded-md text-sm font-medium hover:bg-blue-700"
            >
              Add Topic
            </button>
          </div>

          {/* Topic chips */}
          <div className="flex flex-wrap gap-2">
            {newGroup.topics.map(topic => (
              <div 
                key={topic}
                className="inline-flex items-center gap-1 px-3 py-1 bg-blue-100 text-blue-800 rounded-full"
              >
                <span>{topic}</span>
                <button
                  onClick={() => handleRemoveTopic(topic)}
                  className="w-4 h-4 flex items-center justify-center rounded-full hover:bg-blue-200"
                >
                  ×
                </button>
              </div>
            ))}
          </div>

          {/* Default topic suggestions */}
          <div className="mt-2">
            <p className="text-sm text-gray-600 mb-2">Suggested topics:</p>
            <div className="flex flex-wrap gap-2">
              {defaultTopics.map(topic => (
                !newGroup.topics.includes(topic) && (
                  <button
                    key={topic}
                    onClick={() => {
                      setNewGroup(prevState => ({
                        ...prevState,
                        topics: [...prevState.topics, topic]
                      }));
                    }}
                    className="px-3 py-1 border border-gray-300 rounded-full text-sm hover:bg-gray-50"
                  >
                    + {topic}
                  </button>
                )
              ))}
            </div>
          </div>
        </div>

        {/* Questions Configuration */}
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Total Questions</label>
            <input
              type="number"
              value={newGroup.questionCount}
              onChange={(e) => setNewGroup(prevState => ({
                ...prevState,
                questionCount: parseInt(e.target.value)
              }))}
              min="1"
              className="w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <p className="text-sm text-gray-600">
              Recommended: at least {recommendedQuestions} questions ({newGroup.topics.length} topics × 5 questions each)
            </p>
            <p className="text-sm text-gray-600">
              Estimated time: {Math.round(estimatedTime / 60)} minutes ({estimatedTime} seconds)
            </p>
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Difficulty</label>
            
            <select
              value={newGroup.difficulty}
              onChange={(e) => setNewGroup(prevState => ({
                ...prevState,
                difficulty: e.target.value
              }))}
              className="w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 bg-white"
            >
              <option value="easy">Easy</option>
              <option value="medium">Medium</option>
              <option value="hard">Hard</option>
              <option value="expert">Expert</option>
            </select>

          </div>
        </div>

        {/* Custom Instructions */}
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Custom Instructions (include any specific topics and descriptions)
          </label>
          <textarea
            value={newGroup.instructions}
            onChange={(e) => setNewGroup(prevState => ({
              ...prevState,
              instructions: e.target.value
            }))}
            placeholder="Enter any specific topics or descriptions you'd like to include..."
            rows={4}
            className="w-full rounded-md border border-gray-300 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>

      {/* Form Actions */}
      <div className="mt-6 flex justify-end space-x-2">
        <button
          onClick={() => setCurrentView('dashboard')}
          className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Cancel
        </button>

        <button
          onClick={(e) => {
            e.preventDefault();
            if (selectedGroup) {
              handleUpdateGroup();
            } else {
              handleCreateGroup();
            }
          }}
          className="px-4 py-2 bg-blue-600 text-white rounded-md text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          {selectedGroup ? 'Update Assessment' : 'Create Assessment'}
        </button>
      </div>
    </div>
  );
};

export default SurveyConfig;