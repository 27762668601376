import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { 
  HiUsers, 
  HiHeart, 
  HiChatBubbleOvalLeft, 
  HiPlusCircle, 
  HiBookOpen, 
  HiCalendarDays, 
  HiArrowRight, 
  HiQrCode, 
  HiLink, 
  HiClock, 
  HiLightBulb, 
  HiDocumentText, 
  HiSparkles,
  HiUserGroup,
  HiChevronRight,
  HiHome,
  HiChartBar,
  HiBuildingLibrary,
  HiXCircle
} from "react-icons/hi2";
import AuthenticateModal from '../../components/AuthenticateModal/AuthenticateModal'; // Add import
import Header from '../../components/Header/Header';
import { setTempUserId } from '../../store/actions/userActions';
import { getUserAdminCommunity } from '../../services/dashboardService';


const Banner = () => {
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  return (
    <div className="bg-blue-50 px-4 py-2">
      <div className="max-w-6xl mx-auto flex justify-between items-center">
        <p className="text-sm text-blue-800">Crossvalidation.ai has rebranded to ExamenAI</p>
        <button 
          onClick={() => setIsVisible(false)}
          className="text-blue-600 hover:text-blue-800"
        >
          <HiXCircle className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

const Home = () => {
  const navigate = useNavigate();
  const [showAuthModal, setShowAuthModal] = useState(false);
  const dispatch = useDispatch();
  const { userId, communityId } = useSelector((state) => state.user);

  const handleTryAssessment = () => {
    if (!userId) {
      dispatch(setTempUserId());
    }
    navigate('/trivia', {
      state: {
        modeId: '125',
        modeName: 'Gospel of Luke'
      }
    });
  };



  const TopicScroll = () => {
   const topics = [
     "Gospel of Luke",
     "John Paul II",
     "Lives of the Saints", 
     "Papal Encyclicals",
     "St. Ignatian Spirituality",
     "Acts of the Apostles",
     "Gospel of Matthew",
     "Vatican II"
   ];

   return (
     <div style={{
       overflow: 'hidden',
       whiteSpace: 'nowrap',
       padding: '0.75rem 0',
       marginTop: '2rem'
     }}>
       <div className="text-blue-100/70 text-sm mb-2 text-center">
         Users Have Completed Examen's In These Topics:
       </div>
       <div style={{
         animation: 'marquee 200s linear infinite',
         display: 'inline-block',
         paddingLeft: '100%'
       }}>
         {Array(5).fill(topics).flat().map((topic, i) => (
           <span key={i} className="text-gray-200/80 text-sm mx-36">
             {topic} <span className="mx-2 opacity-50"></span>
           </span>
         ))}
       </div>
       <style>
         {`
           @keyframes marquee {
             0% { transform: translate(0, 0); }
             100% { transform: translate(-100%, 0); }
           }
         `}
       </style>
     </div>
   );
  };


  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <Header />
      <Banner />

      <AuthenticateModal isOpen={showAuthModal} onClose={() => setShowAuthModal(false)} />
      <div className="relative bg-blue-900">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900 to-blue-800 opacity-90" />
        <header className="pt-16 pb-8 px-4 relative">
          <div className="max-w-6xl mx-auto">
            <div className="flex flex-col items-center text-center">
              <p className="text-2xl md:text-4xl lg:text-5xl xl:text-6xl font-medium mb-6 text-blue-100 max-w-3xl">
                Faith Formation is no longer just on Sundays
              </p>
              <p className="text-lg text-blue-200 mb-10 max-w-2xl">
                Engage your ministry (or yourself) with AI generated Exam-Styled theology trivia. Choose one of the default Catholic topics or create one you're interested in. See how you score.    
              </p>
              <div className="flex flex-col sm:flex-row gap-4 sm:gap-6">

                <button 
                  onClick={handleTryAssessment}
                  className="bg-white text-blue-900 hover:bg-blue-50 px-8 py-3 rounded-md text-lg font-medium inline-flex items-center justify-center gap-2 group transition-all duration-200"
                >
                  <HiBookOpen className="w-6 h-6 group-hover:scale-110 transition-transform" />
                  <span>Free Gospel of Luke 5 Question Examen</span>
                </button>

                <button 
                  onClick={async () => {
                    if (userId) {
                      try {
                        const adminCommunity = await getUserAdminCommunity(userId);
                        if (adminCommunity) {
                          navigate(`/admin/${adminCommunity.community_id}`);
                        } else {
                          navigate('/dashboard');
                        }
                      } catch (err) {
                        setShowAuthModal(true);  
                      }
                    } else {
                      setShowAuthModal(true);
                    }
                  }}
                  className="border-2 border-white text-white hover:bg-blue-800/50 px-8 py-3 rounded-md text-lg font-medium inline-flex items-center justify-center gap-2 group transition-all duration-200"
                >
                  <HiBuildingLibrary className="w-6 h-6 group-hover:scale-110 transition-transform" />
                  <span>Customize An Examen For Your Ministry</span>
                </button>
                
              </div>
            </div>
            <TopicScroll />
          </div>
        </header>
      </div>

      {/* Parish Benefits Section */}
      <div className="py-20 px-4">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">An Engaging Approach To Faith Formation</h2>
          
          <div className="grid md:grid-cols-2 gap-12">
            <div>
              <h3 className="text-2xl font-bold mb-6">Engage & Understand</h3>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <span className="bg-blue-100 p-2 rounded mr-4 mt-1">
                    <HiChartBar className="w-5 h-5 text-blue-600" />
                  </span>
                  <p>Assess parish-wide understanding across key theological topics: Saints, Liturgy, Scripture, and Catechesis</p>
                </li>
                <li className="flex items-start">
                  <span className="bg-blue-100 p-2 rounded mr-4 mt-1">
                    <HiSparkles className="w-5 h-5 text-blue-600" />
                  </span>
                  <p>Tailor homilies and events based on identified areas for spiritual growth</p>
                </li>
                <li className="flex items-start">
                  <span className="bg-blue-100 p-2 rounded mr-4 mt-1">
                    <HiUsers className="w-5 h-5 text-blue-600" />
                  </span>
                  <p>View aggregate or individual results to guide pastoral planning</p>
                </li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-2xl font-bold mb-6">Customize & Grow</h3>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <span className="bg-blue-100 p-2 rounded mr-4 mt-1">
                    <HiCalendarDays className="w-5 h-5 text-blue-600" />
                  </span>
                  <p>Drive attendance with pre-event assessments for speakers, Bible studies, and retreats</p>
                </li>
                <li className="flex items-start">
                  <span className="bg-blue-100 p-2 rounded mr-4 mt-1">
                    <HiQrCode className="w-5 h-5 text-blue-600" />
                  </span>
                  <p>Easily distribute via QR codes in bulletins or text message links</p>
                </li>
                <li className="flex items-start">
                  <span className="bg-blue-100 p-2 rounded mr-4 mt-1">
                    <HiLink className="w-5 h-5 text-blue-600" />
                  </span>
                  <p>Maintain engagement with follow-up assessments and topic-focused study groups</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/* Assessment Features */}
      <div className="bg-gray-50 py-20 px-4">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">Trivia-like Gamification For Great Engagement</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="font-bold mb-4">AI-Generated Content</h3>
              <p>Unique questions drawn from Catholic teachings, Scripture, and Church documents with relevant source text</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="font-bold mb-4">Interactive Format</h3>
              <p>Multiple choice, fill-in-blank, and open response questions with 30-second timer for engagement</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h3 className="font-bold mb-4">Immediate Insights</h3>
              <p>Detailed scoring and explanations help participants grow in understanding</p>
            </div>
          </div>
        </div>
      </div>

      {/* Ministry Use Cases */}
      <div className="py-20 px-4">
        <div className="max-w-6xl mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">Your Ministry Is Now Celebrating The Lord Monday through Saturday Too</h2>
          <div className="grid md:grid-cols-4 gap-8">
            <div className="text-center">
              <HiUserGroup className="w-12 h-12 text-blue-600 mx-auto mb-4" />
              <h3 className="font-bold mb-2">Friends</h3>
              <p>Challenge Your Friends</p>
            </div>
            <div className="text-center">
              <HiHeart className="w-12 h-12 text-blue-600 mx-auto mb-4" />
              <h3 className="font-bold mb-2">Youth & Young Adults</h3>
              <p>Engage young Catholics</p>
            </div>
            <div className="text-center">
              <HiBookOpen className="w-12 h-12 text-blue-600 mx-auto mb-4" />
              <h3 className="font-bold mb-2">Bible Study</h3>
              <p>Before and After Meeting Scripture Test</p>
            </div>
            <div className="text-center">
              <HiChatBubbleOvalLeft className="w-12 h-12 text-blue-600 mx-auto mb-4" />
              <h3 className="font-bold mb-2">Adult Formation</h3>
              <p>Religous Education After Confirmation</p>
            </div>
          </div>
        </div>
      </div>

      {/* Final CTA */}
      <div className="bg-blue-900 py-16 px-4">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-white mb-8">
            Improved Faith Formation Awaits
          </h2>
          <button 
            onClick={() => setShowAuthModal(true)}
            className="bg-white text-blue-900 px-8 py-3 rounded-md text-lg font-medium"
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;