// services/dashboard_service.js
import axios from 'axios';
import { API_ENDPOINTS } from "../config/api";

export const getCommunityGroups = async (communityId, userId) => {
  try {
    const response = await axios.get(
      API_ENDPOINTS.GET_COMMUNITY_GROUPS.replace(':communityId', communityId),
      { params: { user_id: userId } }
    );
    
    if (response.status === 200) {
      return response.data;
    }
    throw new Error('Failed to fetch community groups');
  } catch (error) {
    console.error('Error fetching community groups:', error);
    throw error;
  }
};

export const getUserAssessmentHistory = async (communityId, userId) => {
  try {
    const response = await axios.get(
      API_ENDPOINTS.GET_USER_ASSESSMENT_HISTORY.replace(':communityId', communityId),
      { params: { user_id: userId } }
    );
    
    if (response.status === 200) {
      return response.data;
    }
    throw new Error('Failed to fetch assessment history');
  } catch (error) {
    console.error('Error fetching assessment history:', error);
    throw error;
  }
};


export const getCurrentAssessment = async (userId) => {
  try {
    const response = await axios.get(
      API_ENDPOINTS.GET_CURRENT_ASSESSMENT.replace(':userId', userId)
    );
    return response.data;
  } catch (error) {
    if (error.response?.status === 404) return null;
    throw error;
  }
};

export const getAdditionalAssessments = async (userId) => {
  try {
    const response = await axios.get(
      API_ENDPOINTS.GET_ADDITIONAL_ASSESSMENTS.replace(':userId', userId)
    );
    return response.data;
  } catch (error) {
    if (error.response?.status === 404) return [];
    throw error;
  }
};


export const getUserAdminCommunity = async (userId) => {
  try {
    const response = await axios.get(
      API_ENDPOINTS.GET_USER_ADMIN_COMMUNITY.replace(':userId', userId)
    );
    return response.data;
  } catch (error) {
    if (error.response?.status === 404) return null;
    throw error;
  }
};




export default {
  getCommunityGroups,
  getCurrentAssessment,
  getUserAssessmentHistory,
  getAdditionalAssessments,

};