// components/StatsModal/StatsModal.jsx
import React from 'react';
import { HiChartBar, HiUsers, HiCheckCircle, HiSquares2X2 } from "react-icons/hi2";
import { formatScore, getScoreColorClass } from '../../services/statsService';

const StatsModal = ({ isOpen, onClose, stats }) => {
 if (!isOpen || !stats) return null;
 
 return (
   <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
     <div className="relative top-20 mx-auto p-5 max-w-3xl">
       <div className="bg-white rounded-lg shadow-xl p-6">
         {/* Header */}
         <div className="flex justify-between items-center border-b pb-4">
           <h3 className="text-xl font-bold text-gray-900">Assessment Statistics</h3>
           <button 
             onClick={onClose}
             className="text-gray-400 hover:text-gray-500"
           >
             <HiSquares2X2 className="h-6 w-6" />
           </button>
         </div>

         {/* Statistics Grid */}
         <div className="grid grid-cols-3 gap-4 my-6">
           <div className="bg-gray-50 p-4 rounded-lg">
             <div className="flex items-center text-blue-600 mb-2">
               <HiUsers className="h-5 w-5 mr-2" />
               <h4 className="font-medium">Participants</h4>
             </div>
             <p className="text-2xl font-bold">{stats.participants.length}</p>
           </div>

           <div className="bg-gray-50 p-4 rounded-lg">
             <div className="flex items-center text-green-600 mb-2">
               <HiChartBar className="h-5 w-5 mr-2" />
               <h4 className="font-medium">Group Average</h4>
             </div>
             <p className={`text-2xl font-bold ${getScoreColorClass(stats.groupAverage)}`}>
               {formatScore(stats.groupAverage)}
             </p>
           </div>

           <div className="bg-gray-50 p-4 rounded-lg">
             <div className="flex items-center text-purple-600 mb-2">
               <HiCheckCircle className="h-5 w-5 mr-2" />
               <h4 className="font-medium">Your Score</h4>
             </div>
             <p className={`text-2xl font-bold ${getScoreColorClass(stats.userScore)}`}>
               {formatScore(stats.userScore)}
             </p>
           </div>
         </div>

         {/* Topic Performance */}
         <div className="mb-6">
           <h4 className="font-medium mb-4">Topic Performance</h4>
           <div className="space-y-4">
             {Object.entries(stats.topicStats).map(([topic, data]) => (
               <div key={topic} className="border-b pb-3">
                 <div className="flex justify-between mb-2">
                   <span className="font-medium">{topic}</span>
                   <div className="space-x-4">
                     <span className="text-gray-600">
                       Group: {formatScore(data.avgScore)}
                     </span>
                     <span className={getScoreColorClass(data.userScore)}>
                       You: {formatScore(data.userScore)}
                     </span>
                   </div>
                 </div>
                 <div className="w-full h-2 bg-gray-200 rounded-full">
                   <div 
                     className="h-2 bg-blue-600 rounded-full"
                     style={{ width: `${data.avgScore}%` }}
                   />
                 </div>
               </div>
             ))}
           </div>
         </div>

         {/* Participants List */}
         <div>
           <h4 className="font-medium mb-2">Participants</h4>
           <div className="max-h-40 overflow-y-auto bg-gray-50 rounded-lg p-3">
             {stats.participants.map(p => (
               <div 
                 key={p.id}
                 className="py-1 px-2 hover:bg-gray-100 rounded"
               >
                 {p.name}
               </div>
             ))}
           </div>
         </div>
       </div>
     </div>
   </div>
 );
};

export default StatsModal;