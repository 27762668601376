// Timer.jsx
import React, { useState, useEffect } from 'react';
import { HiClock } from 'react-icons/hi2';

const Timer = ({ duration, onTimeExpire, isActive }) => {
  const [timeLeft, setTimeLeft] = useState(duration);

  useEffect(() => {
    // Reset timer when new question starts
    setTimeLeft(duration);
  }, [duration]);


  useEffect(() => {
    if (!isActive) return;
    
    const startTime = Date.now();
    const endTime = startTime + (duration * 1000);
    
    const timer = setInterval(() => {
      const remaining = Math.ceil((endTime - Date.now()) / 1000);
      
      if (remaining <= 0) {
        clearInterval(timer);
        onTimeExpire();
        setTimeLeft(0);
      } else {
        setTimeLeft(remaining);
      }
    }, 100);

    return () => clearInterval(timer);
  }, [onTimeExpire, isActive]);




  return (
    <div className="flex items-center gap-2">
      <HiClock className={`w-5 h-5 ${timeLeft <= 5 ? 'text-red-500' : 'text-gray-500'}`} />
      <span className={`font-medium ${timeLeft <= 5 ? 'text-red-500' : 'text-gray-500'}`}>
        {timeLeft}s
      </span>
    </div>
  );
};

export default Timer;