// src/services/authenticateService.js
import axios from 'axios';
import { API_ENDPOINTS } from "../config/api";

const authenticateService = {
 login: async (credentials) => {
   try {
     const response = await axios.post(API_ENDPOINTS.LOGIN, credentials);
     return response.data;
   } catch (error) {
     throw error.response?.data?.error || 'Login failed';
   }
 },

 register: async (userData) => {
   try {
     const response = await axios.post(API_ENDPOINTS.SIGNUP, userData);
     return response.data;
   } catch (error) {
     throw error.response?.data?.error || 'Registration failed';
   }
 },

 getUserInfo: async (email) => {
   try {
     const response = await axios.post(API_ENDPOINTS.USER_INFORMATION, { email });
     return response.data;
   } catch (error) {
     throw error.response?.data?.error || 'Failed to fetch user information';
   }
 },

 joinCommunity: async (communityId, userId) => {
   try {
     const response = await axios.post(
       API_ENDPOINTS.JOIN_COMMUNITY.replace(':communityId', communityId),
       { user_id: userId }
     );
     return response.data;
   } catch (error) {
     throw error.response?.data?.error || 'Failed to join community';
   }
 },

 joinAfterSignup: async (communityId, userId) => {
   try {
     const response = await axios.post(
       API_ENDPOINTS.JOIN_AFTER_SIGNUP.replace(':communityId', communityId),
       { user_id: userId }
     );
     return response.data;
   } catch (error) {
     throw error.response?.data?.error || 'Failed to join community';
   }
 },

 updateParishAffiliation: async (userId, parishCode) => {
   try {
     const response = await axios.post(API_ENDPOINTS.PARISH_AFFILIATION, {
       userId,
       parishCode
     });
     return response.data;
   } catch (error) {
     throw error.response?.data?.error || 'Failed to update parish affiliation';
   }
 },

 getCommunityInfo: async (communityId) => {
   try {
     const response = await axios.get(API_ENDPOINTS.COMMUNITY_INFO.replace(':communityId', communityId));
     return response.data;
   } catch (error) {
     throw error.response?.data?.error || 'Failed to fetch community info';
   }
 },
};

export default authenticateService;