import React, { useEffect } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';


const InviteHandler = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const communityId = searchParams.get('communityId');
    if (communityId) {
      localStorage.setItem('pendingCommunityId', communityId);
    }
    navigate('/dashboard');
  }, [navigate, searchParams]);
  
  return null;
};

export default InviteHandler;