// quizService.js
import { API_ENDPOINTS } from "../config/api";

export const initializeWebSocket = async (
  effectiveUserId,
  modeId,
  modeName,
  callbacks
) => {
  try {
    // Build WebSocket URL with query parameters
    const queryParams = new URLSearchParams();
    if (modeId) queryParams.append('group_id', modeId);
    if (effectiveUserId) queryParams.append('user_id', effectiveUserId);
    if (modeName) queryParams.append('mode', modeName);

    const wsUrl = `${API_ENDPOINTS.TEXT_SOCKET}${
      queryParams.toString() ? `?${queryParams.toString()}` : ''
    }`;

    console.log("Attempting WebSocket connection to:", wsUrl);
    const ws = new WebSocket(wsUrl);
    
    // Set up WebSocket event handlers
    ws.onmessage = (event) => {
      console.log("Received WebSocket message:", JSON.parse(event.data));
      callbacks.onMessage(event);
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
      callbacks.onError(error);
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
      callbacks.onClose();
    };

    ws.onopen = () => {
      console.log("WebSocket connection opened");
      callbacks.onOpen?.();
      
      // Send session initialization message
      const initMessage = {
        type: 'session.initialize',
        data: {
          userId: effectiveUserId,
          groupId: modeId,
          timestamp: new Date().toISOString()
        }
      };
      console.log("Sending initialization message:", initMessage);
      ws.send(JSON.stringify(initMessage));

      // Begin trivia
      const triviaMessage = {
        type: "input_text",
        content: "begin trivia"
      };
      console.log("Sending begin trivia message:", triviaMessage);
      ws.send(JSON.stringify(triviaMessage));
    };

    // Add connection timeout
    const connectionTimeout = setTimeout(() => {
      if (ws.readyState !== WebSocket.OPEN) {
        console.error('WebSocket connection timeout');
        ws.close();
        callbacks.onError?.('Connection timeout - please try again');
      }
    }, 10000);

    ws.addEventListener('open', () => {
      clearTimeout(connectionTimeout);
    });

    return ws;
  } catch (err) {
    console.error('WebSocket initialization error:', err);
    throw err;
  }
};

export const handleWebSocketMessage = (event, {
  setCurrentQuestion,
  setSelectedOption,
  setAnswerResponse,
  setTriviaProgress,
  setLoading,
  setError
}) => {
  try {
    console.log('=== WebSocket Message Handler Start ===');
    console.log('Raw event data:', event.data);
    
    const data = JSON.parse(event.data);
    console.log('Parsed WebSocket data:', data);
    console.log('Message type:', data.type);
    
    // Debug available handlers
    console.log('Handler availability:', {
      hasSetCurrentQuestion: !!setCurrentQuestion,
      hasSetSelectedOption: !!setSelectedOption,
      hasSetAnswerResponse: !!setAnswerResponse,
      hasSetTriviaProgress: !!setTriviaProgress,
      hasSetLoading: !!setLoading,
      hasSetError: !!setError
    });

    switch (data.type) {
      case 'session.created':
      case 'session.updated':
        console.log('Session event received:', {
          type: data.type,
          sessionData: data.session || 'No session data'
        });
        break;

      case 'response.function_call_arguments.delta':
        // Safely ignore delta updates
        console.log('Function call delta received:', data.delta);
        break;

      case 'response.function_call_arguments.done':
        try {
          console.log('Function call received:', {
            rawArguments: data.arguments,
            callId: data.call_id
          });

          const args = JSON.parse(data.arguments);
          console.log('Parsed arguments:', args);
          console.log('Interaction type:', args.interaction_type);

          if (args.interaction_type === 'trivia_question') {
            console.log('Processing trivia question:', args.trivia_content);
            const { trivia_content } = args;
            
            // Set initial progress stats from question data
            if (setTriviaProgress) {
              setTriviaProgress(prev => ({
                ...prev,
                current_question: trivia_content.question_number - 1,
                total_questions: trivia_content.total_questions
              }));
            }
            
            console.log('Question data to set:', {
              question_text: trivia_content.question_text,
              sourceText: trivia_content.source_text,
              citation: trivia_content.citation,
              questionType: trivia_content.question_type,
              topic: trivia_content.topic,
              difficulty: trivia_content.difficulty,
              hasOptions: !!trivia_content.options
            });
            
            if (setCurrentQuestion) {
              setCurrentQuestion({
                question_text: trivia_content.question_text,
                sourceText: trivia_content.source_text,
                citation: trivia_content.citation,
                questionType: trivia_content.question_type,
                topic: trivia_content.topic,
                difficulty: trivia_content.difficulty,
                options: trivia_content.options || []
              });
            
              console.log('Resetting question state');
              setSelectedOption && setSelectedOption(null);
              setAnswerResponse && setAnswerResponse(null);
              setLoading && setLoading(false);
            }
          

          } else if (args.interaction_type === 'trivia_response') {
            console.log('Processing trivia response:', args.trivia_response);
            const { trivia_response } = args;

            console.log('Trivia Response Debug:', {
                rawIsCorrect: trivia_response.is_correct,
                score: trivia_response.score,
                computedCorrect: trivia_response.score >= 7
            });


            // Handle session completion with delay
            if (trivia_response.progress_stats.is_session_complete) {
                console.log('Session complete, showing final answer before stats');
                
                // First show the final answer response
                if (setAnswerResponse) {

                    setAnswerResponse({
                      isCorrect: trivia_response.is_correct,
                      correct_answer: trivia_response.correct_answer,  // Changed from correctAnswer
                      explanation: trivia_response.explanation,
                      score: trivia_response.score,
                      scoringBreakdown: trivia_response.scoring_breakdown,
                      topic: trivia_response.topic,
                      difficulty: trivia_response.difficulty
                    });
                }

                // Set final stats after delay
                setTimeout(() => {
                    if (setTriviaProgress) {
                        setTriviaProgress({
                            total_questions: trivia_response.progress_stats.total_questions,
                            current_question: trivia_response.progress_stats.current_question,
                            total_points: trivia_response.progress_stats.total_points,
                            correct_answers: trivia_response.progress_stats.correct_answers || 0,
                            is_session_complete: true,
                            max_difficulty_reached: trivia_response.progress_stats.max_difficulty_reached,
                            current_topic: trivia_response.topic,
                            current_difficulty: trivia_response.difficulty
                        });
                    }
                    
                    // Clear states after showing final answer
                    setCurrentQuestion && setCurrentQuestion(null);
                    setSelectedOption && setSelectedOption(null);
                    setAnswerResponse && setAnswerResponse(null);
                    setLoading && setLoading(false);
                }, 3000);
                
                return;
            }

            
            // Regular response processing for non-completed sessions
            console.log('Response data to set:', {
              isCorrect: trivia_response.is_correct,
              hasCorrectAnswer: !!trivia_response.correct_answer,
              hasExplanation: !!trivia_response.explanation,
              score: trivia_response.score,
              hasScoring: !!trivia_response.scoring_breakdown
            });
            
            if (setAnswerResponse) {

              setAnswerResponse({
                isCorrect: trivia_response.is_correct,
                correct_answer: trivia_response.correct_answer,
                explanation: trivia_response.explanation,
                score: trivia_response.score,
                scoringBreakdown: trivia_response.scoring_breakdown,
                topic: trivia_response.topic,
                difficulty: trivia_response.difficulty
              });

            }

            console.log('Progress stats:', trivia_response.progress_stats);
            if (setTriviaProgress) {
              setTriviaProgress({
                total_questions: trivia_response.progress_stats.total_questions,
                current_question: trivia_response.progress_stats.current_question,
                total_points: trivia_response.progress_stats.total_points,
                is_session_complete: trivia_response.progress_stats.is_session_complete,
                max_difficulty_reached: trivia_response.progress_stats.max_difficulty_reached,
                current_topic: trivia_response.topic,
                current_difficulty: trivia_response.difficulty,
                correct_answers: trivia_response.progress_stats.correct_answers || 0
              });
            }
            
            console.log('Response processing complete, setting loading false');
            setLoading && setLoading(false);

          }
        } catch (err) {
          console.error('Function call parsing error:', err);
          console.error('Error details:', {
            message: err.message,
            stack: err.stack,
            rawArguments: data.arguments
          });
          setError && setError('Error processing response data');
          setLoading && setLoading(false);
        }
        break;

      case 'error':
        console.error('WebSocket API error:', {
          error: data.error,
          message: data.error?.message,
          details: data.error?.details || 'No additional details'
        });
        setError && setError(data.error?.message || 'An error occurred during the quiz');
        setLoading && setLoading(false);
        break;

      default:
        console.log('Unhandled message type:', data.type);
        break;
    }
  } catch (err) {
    console.error('WebSocket message handling error:', {
      name: err.name,
      message: err.message,
      stack: err.stack,
      data: event?.data
    });

    if (setError) {
      setError('Error processing response');
    }
    if (setLoading) {
      setLoading(false);
    }
  } finally {
    console.log('=== WebSocket Message Handler End ===');
  }
};




export const handleOptionSelect = (option, wsConnection) => {
  if (wsConnection?.readyState === WebSocket.OPEN) {
    wsConnection.send(JSON.stringify({
      type: "input_text",
      content: option
    }));
  } else {
    console.error('WebSocket is not in OPEN state');
  }
};

export const handleNextQuestion = (wsConnection) => {
  if (wsConnection?.readyState === WebSocket.OPEN) {
    wsConnection.send(JSON.stringify({
      type: "input_text",
      content: "Next question please"
    }));
  } else {
    console.error('WebSocket is not in OPEN state');
  }
};