import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaSpinner, FaCheckCircle, FaDownload, FaClipboard, FaQrcode, FaTimes } from 'react-icons/fa';
import { API_ENDPOINTS } from '../../config/api';

const InviteMemberModal = ({ communityId, userId, onClose, showNotification }) => {
const [qrCode, setQrCode] = useState(null);
const [copied, setCopied] = useState(false);

useEffect(() => {
  fetchQrCode();
}, []);

const fetchQrCode = async () => {
  try {
    const base_url = `${window.location.origin}/invite`;
    const response = await axios.get(
      `${API_ENDPOINTS.GET_INVITE_QR.replace(':communityId', communityId)}?baseUrl=${base_url}`
    );
    setQrCode(response.data);
  } catch (error) {
    console.error('Error fetching QR code:', error);
  }
};

const handleDownloadQR = () => {
  if (!qrCode?.qr_code) return;
  const link = document.createElement('a');
  link.href = `data:image/png;base64,${qrCode.qr_code}`;
  link.download = 'invite-qr.png';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const copyToClipboard = () => {
  navigator.clipboard.writeText(qrCode.invite_url);
  setCopied(true);
  setTimeout(() => setCopied(false), 2000);
};

return (
  <div className="fixed inset-0 bg-black/50 z-50 flex items-center justify-center p-4">
    <div className="bg-white rounded-xl shadow-xl max-w-lg w-full p-6">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h3 className="text-xl font-semibold">Invite Members</h3>
          <p className="text-sm text-gray-500">Share this code or link to invite others</p>
        </div>
        <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
          <FaTimes className="w-5 h-5" />
        </button>
      </div>

      {qrCode?.qr_code ? (
        <div className="space-y-6">
          <div className="flex justify-center">
            <div className="bg-white p-4 rounded-lg shadow-sm border">
              <img 
                src={`data:image/png;base64,${qrCode.qr_code}`}
                alt="Invite QR Code"
                className="w-48 h-48"
              />
            </div>
          </div>

          <button
            onClick={handleDownloadQR}
            className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            <FaDownload className="w-4 h-4" />
            Download QR Code
          </button>

          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Or share this invite link
            </label>
            <div className="flex gap-2">
              <input
                type="text"
                value={qrCode.invite_url}
                readOnly
                className="flex-1 px-3 py-2 border rounded-lg bg-gray-50 text-sm"
              />
              <button
                onClick={copyToClipboard}
                className={`px-4 py-2 rounded-lg border flex items-center gap-2 ${
                  copied 
                    ? 'bg-green-50 text-green-600 border-green-200'
                    : 'bg-gray-50 text-gray-600 border-gray-200 hover:bg-gray-100'
                }`}
              >
                {copied ? <FaCheckCircle className="w-4 h-4" /> : <FaClipboard className="w-4 h-4" />}
                {copied ? 'Copied!' : 'Copy'}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="py-12 text-center">
          <FaSpinner className="w-8 h-8 animate-spin text-blue-600 mx-auto" />
          <p className="mt-2 text-gray-600">Generating invite code...</p>
        </div>
      )}
    </div>
  </div>
);
};

export default InviteMemberModal;