import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { 
  HiPlayCircle, 
  HiClock, 
  HiCheckCircle, 
  HiUserGroup, 
  HiPlus,
  HiChartBar,
  HiSquares2X2,
  HiCheck,
  HiCalendar,
  HiArrowRight
} from "react-icons/hi2";
import InviteMemberModal from '../../components/InviteMemberModal/InviteMemberModal';
import ParticipantsModal from '../../components/ParticipantsModal/ParticipantsModal';
import { 
  getCommunityGroups, 
  getCurrentAssessment, 
  getUserAssessmentHistory, 
  getAdditionalAssessments,
  getUserAdminCommunities
} from '../../services/dashboardService';
import { API_ENDPOINTS } from '../../config/api';
import axios from 'axios';
import AdminNav from '../../components/AdminNav/AdminNav';
import CreateCommunityModal from '../../components/CreateCommunityModal/CreateCommunityModal';
import StatsModal from '../../components/StatsModal/StatsModal';
import { getPrimaryAssessmentStats } from '../../services/statsService';
import Header from '../../components/Header/Header';
import authenticateService from '../../services/authenticateService';
import JoinCommunityModal from '../../components/JoinCommunityModal/JoinCommunityModal';
import { checkAndSetUserId } from '../../store/actions/userActions';


const UserDashboard = () => {
  const navigate = useNavigate();
  const { communityId } = useParams();
  const { userId, tempUserId } = useSelector((state) => state.user);
  const effectiveUserId = userId || tempUserId;
  const dispatch = useDispatch();

  const [showJoinModal, setShowJoinModal] = useState(false);
  const [communityToJoin, setCommunityToJoin] = useState(null);

  const [searchParams] = useSearchParams();
  const communityInvite = searchParams.get('communityId'); // Change from communityInvite

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupParticipants, setGroupParticipants] = useState([]);
  const [showParticipantsModal, setShowParticipantsModal] = useState(false);
  const [showCreateCommunityModal, setShowCreateCommunityModal] = useState(false);

  const [showStatsModal, setShowStatsModal] = useState(false);
  const [primaryStats, setPrimaryStats] = useState(null);
  const [completedSessions, setCompletedSessions] = useState([]);
  const location = useLocation();

  const [communities, setCommunities] = useState([]);
  const [communityData, setCommunityData] = useState({});

  // States
  const [communityGroups, setCommunityGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [currentAssessment, setCurrentAssessment] = useState([]);
  const [assessmentHistory, setAssessmentHistory] = useState([]);
  const [additionalAssessments, setAdditionalAssessments] = useState([]);


  useEffect(() => {
    if (!userId) {
      dispatch(checkAndSetUserId());  // This will create temp ID if no user ID exists
    }
  }, [userId, dispatch]);


  useEffect(() => {
   let mounted = true;
   const fetchDashboardData = async () => {
     if (!effectiveUserId) return;
     
     console.log('Fetching dashboard data for user:', effectiveUserId);
     
     try {
       setLoading(true);
       const [userCommunitiesResponse, completedSessions] = await Promise.all([
         axios.get(`${API_ENDPOINTS.GET_USER_MEMBER_COMMUNITIES}`.replace(':userId', effectiveUserId)),
         axios.get(`${API_ENDPOINTS.GET_USER_COMPLETED_SESSIONS}`.replace(':userId', effectiveUserId))
       ]);
       
       if (mounted) {
         setCommunities(userCommunitiesResponse.data);
         setCompletedSessions(completedSessions.data);

         console.log('Fetching assessments...');
         const [current, additional] = await Promise.all([
           getCurrentAssessment(effectiveUserId),
           getAdditionalAssessments(effectiveUserId)
         ]);
         
         console.log('Current assessments:', current);
         console.log('Additional assessments:', additional);
         console.log('Is temp user?', effectiveUserId >= 10000 && effectiveUserId <= 99999);
         console.log('Has no assessments?', !current?.length && !additional?.length);

         if ((effectiveUserId >= 10000 && effectiveUserId <= 99999) || (!current?.length && !additional?.length)) {
           console.log('Setting default assessments');
           
           const defaultPrimary = {
             id: "129",
             name: "Parish Faith Formation Assessment Sample",
             community_name: "Faith Formation",
             description: "",
             questionCount: 5,
             totalResponses: 0,
             topics: ["Faith Formation"],
             difficulty: "hard"
           };


          const defaultAdditional = [
            {
              id: "131", // Using the previous Gospel of Luke ID
              name: "Gospel of Luke",
              description: "Understanding the Gospel of Luke",
              questionCount: 5,
              difficulty: "hard"
            },
            {
              id: "130",
              name: "Advanced Theology",
              description: "Challenging theological questions for advanced Catholic scholars",
              questionCount: 5,
              difficulty: "expert"
            }

           ];

           setCurrentAssessment([defaultPrimary]);
           setAdditionalAssessments(defaultAdditional);
           
           console.log('Default assessments set:', [defaultPrimary], defaultAdditional);
         } else {
           console.log('Setting fetched assessments');
           setCurrentAssessment(Array.isArray(current) ? current : [current]);
           setAdditionalAssessments(additional);
         }
       }
     } catch (err) {
       if (mounted) {
         setError('Failed to fetch dashboard data');
         console.error('Dashboard data fetch error:', err);
       }
     } finally {
       if (mounted) setLoading(false);
     }
   };

   fetchDashboardData();
   return () => { mounted = false; };
  }, [effectiveUserId]);



  useEffect(() => {
      const fetchCompletedSessions = async () => {
        try {
          const response = await axios.get(
            `${API_ENDPOINTS.GET_USER_COMPLETED_SESSIONS}`.replace(':userId', effectiveUserId)
          );
          setCompletedSessions(response.data);
        } catch (err) {
          console.error('Error fetching sessions:', err);
        }
      };

      if (effectiveUserId) {
        fetchCompletedSessions();
      }
    }, [effectiveUserId]);


  const handleJoinSuccess = async () => {
    try {
      setLoading(true);
      const pendingCommunityId = localStorage.getItem('pendingCommunityId');
      await authenticateService.joinAfterSignup(pendingCommunityId, effectiveUserId);
      
      // Fetch data with the correct communityId
      const [currentAssessmentData, additionalAssessmentsData] = await Promise.all([
        getCurrentAssessment(pendingCommunityId, effectiveUserId),
        getAdditionalAssessments(pendingCommunityId, effectiveUserId)
      ]);

      setCurrentAssessment(currentAssessmentData);
      setAdditionalAssessments(additionalAssessmentsData);
      
      setShowJoinModal(false);
      localStorage.removeItem('pendingCommunityId');
      
      // Navigate to the community dashboard
      navigate(`/dashboard/${pendingCommunityId}`);
    } catch (err) {
      setError('Failed to join community');
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    const pendingCommunityId = localStorage.getItem('pendingCommunityId');
    
    if (pendingCommunityId) {
      authenticateService.getCommunityInfo(pendingCommunityId)
        .then(response => {
          setCommunityToJoin(response);
          setShowJoinModal(true);
          // Don't remove pendingCommunityId here - it's needed for signup
        })
        .catch(error => {
          console.error('Error fetching community:', error);
        });
    }
  }, []);

  const handleStartAssessment = (group) => {
    console.log("handleStartAssessment called with group:", group);
    console.log("Navigating to /trivia with state:", { 
      modeId: group.id, 
      modeName: group.name 
    });
    
    navigate(`/trivia`, { 
      state: { 
        modeId: group.id,
        modeName: group.name
      }
    });
  };

  const handleViewStats = async (assessment) => {
    try {
      const stats = await getPrimaryAssessmentStats(assessment.id, assessment.community_id, effectiveUserId);
      setPrimaryStats(stats);
      setShowStatsModal(true);
    } catch (err) {
      console.error(err);
    }
  };


  const handleHistoryClick = async (session) => {
    try {
      const stats = await getPrimaryAssessmentStats(session.group_id, effectiveUserId);
      setPrimaryStats(stats);
      setShowStatsModal(true);
    } catch (err) {
      console.error('Error fetching history stats:', err);
    }
  };


  // Add new function to fetch participants
  const handleGroupClick = async (assessment) => {
    try {
      const response = await axios.get(
        `${API_ENDPOINTS.GET_GROUP_PARTICIPANTS.replace(':groupId', assessment.id)}?community_id=${communityId}`
      );
      setGroupParticipants(response.data);
      setSelectedGroup(assessment);
      setShowParticipantsModal(true);
    } catch (error) {
      console.error('Error fetching participants:', error);
    }
  };

  const renderPrimaryAssessment = () => {
   if (!currentAssessment?.length) return null;
   
   return (
     <div className="space-y-6">
       {currentAssessment.map(assessment => (
         <div 
           key={assessment.id}
           onClick={(e) => {
             if (e.target.tagName !== 'BUTTON') {
               handleViewStats(assessment);
             }
           }}
           className="mb-8 bg-gradient-to-br from-blue-50 to-white rounded-xl shadow-lg border border-blue-100 overflow-hidden cursor-pointer hover:bg-blue-100/50 transition-all"
         >
           <div className="p-8">
             <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center gap-6 mb-6">
               <div className="flex-1 min-w-0">
                 <div className="inline-flex items-center px-3 py-1 bg-blue-100 text-blue-700 rounded-full text-sm font-medium mb-4">
                   <HiCalendar className="w-4 h-4 mr-1" />
                   Current Examen
                 </div>
                 <h2 className="text-2xl font-bold text-gray-900 mb-2">
                   {assessment.name}
                 </h2>
                 {assessment.community_name && (
                   <p className="text-gray-600 mb-2">Community: {assessment.community_name}</p>
                 )}
                 <p className="text-gray-600 text-lg leading-relaxed max-w-3xl">
                   {assessment.description}
                 </p>
               </div>
               <button
                 onClick={(e) => {
                   e.stopPropagation();
                   handleStartAssessment(assessment);
                 }}
                 className="flex-shrink-0 inline-flex items-center px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 shadow-sm transition-colors group whitespace-nowrap"
               >
                 {assessment.isCompleted ? 'Retake With New Questions' : 'Begin Examen'}
                 <HiArrowRight className="w-5 h-5 ml-2 transform group-hover:translate-x-1 transition-transform" />
               </button>
             </div>

             <div className="grid grid-cols-2 lg:grid-cols-4 gap-4">
               <div className="bg-white rounded-lg px-4 py-3 shadow-sm border border-gray-100">
                 <div className="flex items-center text-gray-600 mb-1.5">
                   <HiCheckCircle className="w-4 h-4 mr-2 text-blue-600" />
                   <span className="text-sm">Questions</span>
                 </div>
                 <p className="text-xl font-semibold text-gray-900 text-center">
                   {assessment.questionCount}
                 </p>
               </div>
               <div className="bg-white rounded-lg px-4 py-3 shadow-sm border border-gray-100">
                 <div className="flex items-center text-gray-600 mb-1.5">
                   <HiClock className="w-4 h-4 mr-2 text-blue-600" />
                   <span className="text-sm">Duration</span>
                 </div>
                 <p className="text-xl font-semibold text-gray-900 text-center">
                   {`${Math.ceil(assessment.questionCount * 45 / 60)} minutes`}
                 </p>
               </div>
               <div 
                 className="bg-white rounded-lg px-4 py-3 shadow-sm border border-gray-100 cursor-pointer hover:bg-gray-50 transition-colors"
                 onClick={(e) => {
                   e.stopPropagation();
                   handleGroupClick(assessment);
                 }}
               >
                 <div className="flex items-center text-gray-600 mb-1.5">
                   <HiUserGroup className="w-4 h-4 mr-2 text-blue-600" />
                   <span className="text-sm">Participants</span>
                 </div>
                 <p className="text-xl font-semibold text-gray-900 text-center">
                   {assessment.totalResponses}
                 </p>
               </div>
               <div className="bg-white rounded-lg px-4 py-3 shadow-sm border border-gray-100">
                 <div className="flex items-center text-gray-600 mb-1.5">
                   <HiSquares2X2 className="w-4 h-4 mr-2 text-blue-600" />
                   <span className="text-sm">Topics</span>
                 </div>
                 <p className="text-xl font-semibold text-gray-900 text-center">
                   {assessment.topics?.length || 0}
                 </p>
               </div>
             </div>
           </div>
         </div>
       ))}
     </div>
   );
  };


  const renderAssessmentHistory = () => {
    return (
      <div className="space-y-4">
        {completedSessions.length > 0 ? (
          completedSessions.map(session => (
            <div 
              key={session.session_id} 
              className="border-b pb-4 cursor-pointer hover:bg-gray-50 transition-colors"
              onClick={() => handleHistoryClick(session)} 
              role="button"
              aria-label={`View stats for ${session.group_name}`}
            >
              <div className="flex justify-between items-start">
                <div>
                  <h4 className="font-medium text-gray-900">{session.group_name}</h4>
                  <p className="text-sm text-gray-600">
                    Completed {new Date(session.completion_date).toLocaleDateString()}
                  </p>
                </div>
                <div className="flex items-center gap-3">
                  <div className="text-right">
                    <p className="text-lg font-semibold text-blue-600">
                      {(session.total_score / (session.questions_answered * 10) * 100).toFixed(1)}%
                    </p>
                    <p className="text-sm text-gray-600">
                      Final Level: {session.max_difficulty}
                    </p>
                  </div>
                  <HiChartBar className="w-6 h-6 text-blue-600 flex-shrink-0" />
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-600 text-center py-4">
            No completed assessments yet
          </p>
        )}
      </div>
    );
  };




  const renderAdditionalReflections = () => (
    <div className="space-y-4">
      {additionalAssessments.map(assessment => (
        <div key={assessment.id} className="border-b pb-4">
          <div className="flex justify-between items-start">
            <div>
              <h4 className="font-medium text-gray-900">{assessment.name}</h4>
              <p className="text-sm text-gray-600">{assessment.community_name}</p>
              <p className="text-sm text-gray-600">{assessment.description}</p>
            </div>
            <button
              onClick={() => handleStartAssessment({
                id: assessment.id,
                name: assessment.name
              })}
              className="px-3 py-1 text-sm text-blue-600 border border-blue-600 rounded-md hover:bg-blue-50"
            >
              {assessment.status === 'completed' ? 'Retake' : 'Start'}
            </button>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50">
      <Header />
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-8 gap-4">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Your Faith Journey</h1>
            <p className="text-gray-600">
              {currentAssessment?.[0]?.community_name || ''}
            </p>
          </div>

          <div className="flex items-center gap-3">
            <AdminNav userId={effectiveUserId} />
            <button
              onClick={() => setShowCreateCommunityModal(true)}
              className="inline-flex items-center px-4 py-2 text-sm font-medium bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              <HiPlus className="w-4 h-4 mr-2" />
              Create Community
            </button>
          </div>

        </div>
        {loading ? (
          <div className="text-center py-12">
            <div className="animate-spin h-8 w-8 text-blue-600 mx-auto mb-4" />
            <p className="text-gray-600">Loading dashboard...</p>
          </div>
        ) : error ? (
          <div className="text-center py-12">
            <p className="text-red-600">{error}</p>
          </div>
        ) : (
          <div className="space-y-8">
            {renderPrimaryAssessment()}
            <div className="grid md:grid-cols-2 gap-6">
              <div className="bg-white rounded-lg shadow-lg border border-gray-200 p-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
                  <HiChartBar className="w-5 h-5 mr-2 text-blue-600" />
                  Your Completion History
                </h3>
                {renderAssessmentHistory()}
              </div>
              <div className="bg-white rounded-lg shadow-lg border border-gray-200 p-6">
                <h3 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
                  <HiSquares2X2 className="w-5 h-5 mr-2 text-blue-600" />
                  Additional Examens
                </h3>
                {renderAdditionalReflections()}
              </div>
            </div>
          </div>
        )}
        
        {showInviteModal && (
          <InviteMemberModal
            communityId={communityId}
            userId={effectiveUserId}
            onClose={() => setShowInviteModal(false)}
          />
        )}
        
        <ParticipantsModal
          isOpen={showParticipantsModal}
          onClose={() => setShowParticipantsModal(false)}
          groupName={selectedGroup?.name}
          participants={groupParticipants}
        />
        
        {showCreateCommunityModal && (
          <CreateCommunityModal
            isOpen={showCreateCommunityModal}
            onClose={() => setShowCreateCommunityModal(false)}
            onSuccess={(data) => {
              navigate(`/admin/${data.community_id}`);
            }}
          />
        )}

        <StatsModal
          isOpen={showStatsModal}
          onClose={() => setShowStatsModal(false)}
          stats={primaryStats}
        />

        {showJoinModal && communityToJoin && (
          <JoinCommunityModal
            isOpen={showJoinModal}
            communityId={communityToJoin.id}
            communityName={communityToJoin.name}
            onClose={() => {
              setShowJoinModal(false);
              window.history.replaceState({}, '', window.location.pathname);
            }}
            onJoinSuccess={handleJoinSuccess}
          />
        )}
      </main>
    </div>
  );

};

export default UserDashboard;