import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from 'react-router-dom';
import {
  HiBookOpen,
  HiChevronRight,
  HiHome,
  HiCheckCircle,
  HiXCircle,
  HiQuestionMarkCircle,
  HiClipboardDocument,
  HiAcademicCap,
  HiArrowPath,
  HiArrowTopRightOnSquare,
  HiLightBulb
} from "react-icons/hi2";

import {
  handleWebSocketMessage,
  initializeWebSocket,
  handleOptionSelect,
  handleNextQuestion,
} from '../../services/quizService';
import axios from 'axios';
import { API_ENDPOINTS } from '../../config/api';
import NameInputModal from '../../components/NameInputModal/NameInputModal';
import Timer from '../../components/Timer/Timer';
import Header from '../../components/Header/Header';


const Trivia = () => {
  const navigate = useNavigate();
  const { userId, tempUserId } = useSelector((state) => state.user);
  const location = useLocation();
  const modeId = location.state?.modeId;
  const modeName = location.state?.modeName;
  const effectiveUserId = userId || tempUserId;
  const [isTimerActive, setIsTimerActive] = useState(false);

  const [showNameModal, setShowNameModal] = useState(false);
  const [userName, setUserName] = useState('');

  // Core state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [wsConnection, setWsConnection] = useState(null);
  const [sessionActive, setSessionActive] = useState(false);

  // Question state
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [answerResponse, setAnswerResponse] = useState(null);
  const [userInput, setUserInput] = useState('');

  // Progress tracking
  const [triviaProgress, setTriviaProgress] = useState({
    total_questions: 0,
    current_question: 0,
    total_points: 0,
    correct_answers: 0,
    is_session_complete: false,
    max_difficulty_reached: null,
    current_topic: null,
    current_difficulty: null
  });


  // Initialize WebSocket
  useEffect(() => {
   console.log('=== WebSocket Effect Start ===');
   console.log('Init state:', { wsConnection, modeId, effectiveUserId });

   const initWs = async () => {
     if (!wsConnection && modeId && effectiveUserId) {
       try {
         console.log('Creating WebSocket with:', { effectiveUserId, modeId, modeName });
         
         const ws = await initializeWebSocket(effectiveUserId, modeId, modeName, {
           onMessage: (event) => {
             console.log('WS Message received:', event.data);
             handleWebSocketMessage(event, {
               setCurrentQuestion,
               setSelectedOption, 
               setAnswerResponse,
               setTriviaProgress,
               setLoading,
               setError
             });
           },
           onError: (error) => {
             console.log('WS Error:', error);
             setError('Connection error occurred');
           },
           onClose: () => {
             console.log('WS Closed');
             setWsConnection(null);
             setSessionActive(false);
           },
           onOpen: () => {
             console.log('WS Opened');
             setSessionActive(true);
             setLoading(false);
           }
         });
         
         console.log('WS initialized, setting connection');
         setWsConnection(ws);

       } catch (err) {
         console.error('WS init error:', err);
         setError('Failed to connect to quiz service');
         setLoading(false);
       }
     }
   };

   initWs();

   return () => {
     if (wsConnection) {
       console.log('Cleaning up WS connection');
       wsConnection.close();
       setWsConnection(null);
     }
   };
  }, [effectiveUserId, modeId, modeName]);


  const handleCompletionNameCheck = async () => {
    if (effectiveUserId >= 10000 && effectiveUserId <= 99999) {
      try {
        const response = await axios.get(
          API_ENDPOINTS.GET_TRIVIA_USER_NAME.replace(':userId', effectiveUserId)
        );
        if (response.data.display_name) {
          setUserName(response.data.display_name);
        } else {
          setShowNameModal(true);
        }
      } catch (error) {
        setShowNameModal(true);
      }
    }
  };

  useEffect(() => {
    if (triviaProgress.is_session_complete) {
      handleCompletionNameCheck();
    }
  }, [triviaProgress.is_session_complete, effectiveUserId]);



  // Update handleNameSubmit to activate timer after name submission
  const handleNameSubmit = async (name) => {
    try {
      await axios.post(API_ENDPOINTS.CREATE_TRIVIA_USER, {
        user_id: effectiveUserId,
        group_id: modeId,
        display_name: name
      });
      setUserName(name);
      setShowNameModal(false);
      setIsTimerActive(true);  // Start timer after name submission
    } catch (error) {
      console.error('Error saving user name:', error);
    }
  };


  // Start timer when question is loaded or after name modal
  useEffect(() => {
    if (currentQuestion && !answerResponse && !showNameModal) {
      setIsTimerActive(true);
    } else {
      setIsTimerActive(false);
    }
  }, [currentQuestion, answerResponse, showNameModal]);

  // Handle timer expiration
  const handleTimeExpire = () => {
    if (currentQuestion) {
      // Use the same onOptionSelect flow as manual submissions
      if (currentQuestion.questionType === 'multiple_choice') {
        handleOptionSelect("My time expired for this question. I don't know the answer", wsConnection);
      } else {
        const response = userInput.trim() || "My time expired for this question. I don't know the answer";
        handleOptionSelect(response, wsConnection);
      }
      setUserInput('');
      setLoading(true);
    }
  };


  const onOptionSelect = (option) => {
    setSelectedOption(option);
    setLoading(true);
    handleOptionSelect(option, wsConnection);
  };

  const onNextQuestion = () => {
    handleNextQuestion(wsConnection);
    setCurrentQuestion(null);
    setSelectedOption(null);
    setAnswerResponse(null);
    setUserInput('');
    setLoading(true);
  };

  const handleSubmit = () => {
    if (!userInput.trim()) return;
    onOptionSelect(userInput.trim());
  };

  const handleReturnHome = () => {
    navigate(`/dashboard/${effectiveUserId}`);
  };

  const calculateProgress = () => {
    return (triviaProgress.current_question / triviaProgress.total_questions) * 100;
  };
  

  const renderAnswer = () => (
   <div className="bg-white rounded-lg shadow-md mb-4 overflow-hidden">
     <div className="p-3 sm:p-4 border-b">
       <div className={`flex items-center gap-1 text-lg font-bold
         ${answerResponse?.isCorrect ? 'text-green-600' : 'text-red-600'}
       `}>
         {answerResponse?.isCorrect ? (
           <HiCheckCircle className="w-5 h-5" />
         ) : (
           <HiXCircle className="w-5 h-5" />
         )}
         {answerResponse?.isCorrect ? 'Correct!' : 'Incorrect'}
         {answerResponse?.score !== undefined && ` (${answerResponse.score}/10)`}
       </div>
     </div>
     
     <div className="p-3 sm:p-4 space-y-3">
       <div className="bg-gray-50 p-3 rounded-lg">
         <strong className="text-sm text-gray-700">Correct Answer:</strong>
         <p className="mt-1 text-gray-900">{answerResponse?.correct_answer}</p>
       </div>
       
       <div className="bg-blue-50 p-3 rounded-lg">
         <strong className="text-sm text-gray-700">Explanation:</strong>
         <p className="mt-1 text-gray-900">{answerResponse?.explanation}</p>
       </div>
       {answerResponse?.scoringBreakdown && (
         <div className="space-y-3">
           {answerResponse.scoringBreakdown.correct_reasons && (
             <div className="bg-green-50 p-3 rounded-lg">
               <div className="flex items-center gap-1">
                 <HiCheckCircle className="w-4 h-4 text-green-600" />
                 <strong className="text-sm text-green-700">What You Got Right:</strong>
               </div>
               <p className="mt-1 text-gray-900">{answerResponse.scoringBreakdown.correct_reasons}</p>
             </div>
           )}
           {answerResponse.scoringBreakdown.incorrect_reasons && (
             <div className="bg-amber-50 p-3 rounded-lg">
               <div className="flex items-center gap-1">
                 <HiLightBulb className="w-4 h-4 text-amber-600" />
                 <strong className="text-sm text-amber-700">Areas for Improvement:</strong>
               </div>
               <p className="mt-1 text-gray-900">{answerResponse.scoringBreakdown.incorrect_reasons}</p>
             </div>
           )}
         </div>
       )}
     </div>
     <div className="p-3 sm:p-4 bg-gray-50 border-t">
       <button
         onClick={onNextQuestion}
         disabled={loading}
         className={`w-full flex items-center justify-center gap-2 bg-indigo-600 text-white px-3 py-2 rounded-lg
           transition-colors hover:bg-indigo-700 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500
           ${loading ? 'opacity-50 cursor-not-allowed' : ''}
         `}
       >
         Next Question <HiChevronRight className="w-4 h-4" />
       </button>
     </div>
   </div>
  );



  const renderProgressHeader = () => (
    <div className="bg-white rounded-lg shadow-md mb-4 overflow-hidden">
      <div className="p-3 sm:p-4">
        <div className="flex justify-between items-center mb-2">
          <div className="flex items-center gap-2">
            <HiClipboardDocument className="w-5 h-5 text-indigo-600" />
            <h2 className="text-lg font-bold">Progress</h2>
            <span className="text-sm text-gray-600 ml-2">
              Q{triviaProgress.current_question + 1}/{triviaProgress.total_questions}
            </span>
          </div>
          {triviaProgress.current_topic && (
            <span className="text-xs text-gray-500">
              {triviaProgress.current_topic} ({triviaProgress.current_difficulty})
            </span>
          )}
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 mb-2">
          <div className="text-center p-2 bg-indigo-50 rounded-lg">
            <div className="text-xl font-bold text-indigo-600">
              {triviaProgress.total_questions}
            </div>
            <div className="text-xs text-gray-500">Total Questions</div>
          </div>
          <div className="text-center p-2 bg-green-50 rounded-lg">
            <div className="text-xl font-bold text-green-600">
              {triviaProgress.correct_answers}
            </div>
            <div className="text-xs text-gray-500">Correct</div>
          </div>
          <div className="text-center p-2 bg-blue-50 rounded-lg">
            <div className="text-xl font-bold text-blue-600">
              {triviaProgress.total_points}
            </div>
            <div className="text-xs text-gray-500">Points</div>
          </div>
          <div className="text-center p-2 bg-purple-50 rounded-lg">
            <div className="text-xl font-bold text-purple-600">
              {(triviaProgress.total_points / triviaProgress.current_question || 0).toFixed(1)}
            </div>
            <div className="text-xs text-gray-500">Avg Points</div>
          </div>
        </div>
        <div className="bg-gray-200 rounded-full h-1.5 overflow-hidden">
          <div 
            className="h-full bg-indigo-600 transition-all duration-500 ease-in-out"
            style={{ width: `${calculateProgress()}%` }}
          />
        </div>
      </div>
    </div>
  );

  const renderQuestion = () => (
   <div className="bg-white rounded-lg shadow-md mb-4 overflow-hidden">
     <div className="p-3 sm:p-4 border-b">
       <div className="flex justify-between items-center mb-2">
         <div className="flex items-center gap-1">
           <HiBookOpen className="w-5 h-5 text-indigo-600" />
           <div>
             <span className="text-sm text-gray-600">{modeName} • Q{triviaProgress.current_question + 1}</span>
             <span className="text-xs text-gray-500 block">
               {currentQuestion?.topic} • {currentQuestion?.difficulty}
             </span>
           </div>
         </div>
         <Timer 
           duration={45} 
           onTimeExpire={handleTimeExpire}
           isActive={isTimerActive}
         />
       </div>
       
       <div className="bg-blue-50 p-3 rounded-lg mb-2 text-sm">
         <p className="text-gray-700 italic">{currentQuestion?.sourceText}</p>
         <cite className="text-gray-500 block mt-1">{currentQuestion?.citation}</cite>
       </div>
       
       <div className="flex items-start gap-1">
         <HiQuestionMarkCircle className="w-5 h-5 text-indigo-600 mt-0.5 flex-shrink-0" />
         <h2 className="text-lg font-bold">{currentQuestion?.question_text}</h2>
       </div>
     </div>
     
     <div className="p-3 sm:p-4">
       {currentQuestion?.questionType === 'multiple_choice' && (
         <div className="space-y-2">
           {currentQuestion.options.map((option, index) => (
             <button
               key={index}
               onClick={() => onOptionSelect(option)}
               disabled={loading || selectedOption !== null}
               className={`w-full text-left p-3 rounded-lg border-2 transition-all duration-200
                 ${selectedOption === option 
                   ? 'bg-indigo-50 border-indigo-500 shadow-sm' 
                   : 'border-gray-200 hover:border-indigo-300 hover:bg-indigo-50'
                 } ${loading || selectedOption !== null ? 'opacity-50 cursor-not-allowed' : 'hover:shadow-sm'}
               `}
             >
               {option}
             </button>
           ))}
         </div>
       )}

       {(currentQuestion?.questionType === 'fill_in_blank' || currentQuestion?.questionType === 'free_response') && (
         <div className="space-y-2">
           {currentQuestion.questionType === 'free_response' ? (
             <textarea
               value={userInput}
               onChange={(e) => setUserInput(e.target.value)}
               placeholder="Type your answer..."
               rows={4}
               disabled={loading}
               className="w-full p-2 border-2 rounded-lg focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500
                 disabled:opacity-50 disabled:cursor-not-allowed"
             />
           ) : (
             <input
               type="text"
               value={userInput}
               onChange={(e) => setUserInput(e.target.value)}
               placeholder="Type your answer..."
               disabled={loading}
               className="w-full p-2 border-2 rounded-lg focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500
                 disabled:opacity-50 disabled:cursor-not-allowed"
             />
           )}
           <button
             onClick={handleSubmit}
             disabled={!userInput.trim() || loading}
             className={`w-full flex items-center justify-center gap-2 bg-indigo-600 text-white px-3 py-2 rounded-lg
               transition-colors hover:bg-indigo-700 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500
               ${(!userInput.trim() || loading) ? 'opacity-50 cursor-not-allowed' : ''}
             `}
           >
             <HiArrowTopRightOnSquare className="w-4 h-4" />
             Submit Answer
           </button>
         </div>
       )}
     </div>
   </div>
  );


  const renderFinalStats = () => {
    return (
      <div className="bg-white rounded-lg shadow-md mb-6 overflow-hidden">
        <div className="p-6 text-center border-b">
          <div className="flex items-center justify-center gap-2">
            <HiAcademicCap className="w-8 h-8 text-indigo-600" />
            <h2 className="text-2xl font-bold">Examen Complete!</h2>
          </div>
          <p className="mt-2 text-gray-600">
            Great job completing all {triviaProgress.total_questions} questions!
          </p>
        </div>
        
        <div className="p-6">
          <div className="grid grid-cols-2 gap-4 mb-6">
            <div className="text-center p-4 bg-indigo-50 rounded-lg">
              <div className="text-3xl font-bold text-indigo-600">
                {triviaProgress.total_points}
              </div>
              <div className="text-sm text-gray-600">Total Points</div>
            </div>
            <div className="text-center p-4 bg-green-50 rounded-lg">
              <div className="text-3xl font-bold text-green-600">
                {Math.round((triviaProgress.correct_answers / triviaProgress.total_questions) * 100)}%
              </div>
              <div className="text-sm text-gray-600">Success Rate</div>
            </div>
          </div>

          <div className="space-y-4">
            <div className="p-4 bg-blue-50 rounded-lg">
              <h3 className="font-semibold text-gray-900">Difficulty Level</h3>
              <p className="text-lg font-medium text-blue-700 mt-1">
                {triviaProgress.max_difficulty_reached || 'N/A'}
              </p>
            </div>
          </div>

          <div className="mt-6">
            <button
              onClick={handleReturnHome}
              className="w-full flex items-center justify-center gap-2 bg-indigo-600 text-white px-4 py-3 rounded-lg
                transition-colors hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <HiHome className="w-5 h-5" />
              Return To Dashboard
            </button>
          </div>
        </div>
      </div>
    );
  };


  return (
    <>
      <Header />
      <div className="min-h-screen bg-gray-50 pt-1 px-3 sm:px-4">
        <div className="max-w-4xl mx-auto py-3">
          {triviaProgress.is_session_complete ? (
            <div className="bg-white rounded-lg shadow-md mb-4 overflow-hidden">
              <div className="p-4 text-center border-b">
                <div className="flex items-center justify-center gap-1">
                  <HiAcademicCap className="w-6 h-6 text-indigo-600" />
                  <h2 className="text-xl font-bold">Examen Complete!</h2>
                </div>
                <p className="mt-1 text-sm text-gray-600">
                  Great job completing all {triviaProgress.total_questions} questions!
                </p>
              </div>
              
              <div className="p-4">
                <div className="grid grid-cols-2 gap-3 mb-4">
                  <div className="text-center p-3 bg-indigo-50 rounded-lg">
                    <div className="text-2xl font-bold text-indigo-600">
                      {triviaProgress.total_points}
                    </div>
                    <div className="text-xs text-gray-600">Total Points</div>
                  </div>
                  <div className="text-center p-3 bg-green-50 rounded-lg">
                    <div className="text-2xl font-bold text-green-600">
                      {Math.round((triviaProgress.correct_answers / triviaProgress.total_questions) * 100)}%
                    </div>
                    <div className="text-xs text-gray-600">Success Rate</div>
                  </div>
                </div>
                {effectiveUserId >= 10000 && effectiveUserId <= 99999 && (
                  <div className="mb-4 p-3 bg-blue-50 rounded-lg text-sm text-blue-700">
                    Register for a free account to maintain Examen scores and completion history.
                  </div>
                )}
                <button
                  onClick={handleReturnHome}
                  className="w-full flex items-center justify-center gap-2 bg-indigo-600 text-white px-3 py-2 rounded-lg
                    transition-colors hover:bg-indigo-700 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <HiHome className="w-4 h-4" />
                  Return To Dashboard
                </button>
              </div>
            </div>
          ) : (
            <>
              {renderProgressHeader()}
              {currentQuestion && !answerResponse && renderQuestion()}
              {answerResponse && renderAnswer()}
              
              {loading && !currentQuestion && (
                <div className="bg-white rounded-lg shadow-md p-4">
                  <div className="flex flex-col items-center justify-center gap-2">
                    <HiArrowPath className="w-6 h-6 text-indigo-600 animate-spin" />
                    <p className="text-sm text-gray-600">Loading your next question...</p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );

};

export default Trivia;