import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../store/actions/userActions';
import AuthenticateModal from '../AuthenticateModal/AuthenticateModal';

const Header = () => {
 const navigate = useNavigate();
 const dispatch = useDispatch();
 const { userId, tempUserId, role } = useSelector((state) => state.user);
 const [showAuthModal, setShowAuthModal] = useState(false);
 const [authMode, setAuthMode] = useState('login');
 const isRealUser = userId && !(userId >= 10000 && userId <= 99999);

 const handleLogout = () => {
   dispatch(logout());
   navigate('/');
 };

 const handleAuthClick = (mode) => {
   setAuthMode(mode);
   setShowAuthModal(true);
 };

 return (
   <>
     <header className="bg-white border-b">
       <div className="max-w-7xl mx-auto px-2 sm:px-4">
         <div className="flex justify-between items-center h-16">
           <div className="flex items-center space-x-2">
             <img src="/logo.png" alt="Examen.ai Logo" className="h-6 w-auto sm:h-8" />
             <Link to="/dashboard" className="text-lg sm:text-xl font-bold text-blue-600 whitespace-nowrap">
               ExamenAI
             </Link>
           </div>
           <nav className="flex items-center space-x-3 sm:space-x-6">
             {isRealUser ? (
               <>
                 <Link to={`/dashboard/${userId}`} className="text-gray-600 hover:text-gray-900 text-sm sm:text-base whitespace-nowrap">
                   Dashboard
                 </Link>
                 {role === 'admin' && (
                   <Link to="/admin" className="text-gray-600 hover:text-gray-900 text-sm sm:text-base whitespace-nowrap">
                     Admin
                   </Link>
                 )}
                 <button onClick={handleLogout} className="text-gray-600 hover:text-gray-900 text-sm sm:text-base whitespace-nowrap">
                   Sign Out
                 </button>
               </>
             ) : (
               <>
                 <Link to="/dashboard" className="text-gray-600 hover:text-gray-900 text-sm sm:text-base whitespace-nowrap">
                   Dashboard
                 </Link>
                 <button
                   onClick={() => handleAuthClick('register')}
                   className="px-2 py-1 sm:px-4 sm:py-2 text-sm sm:text-base rounded-lg bg-blue-600 text-white hover:bg-blue-700 whitespace-nowrap"
                 >
                   Register
                 </button>
                 <button
                   onClick={() => handleAuthClick('login')}
                   className="text-gray-600 hover:text-gray-900 text-sm sm:text-base whitespace-nowrap"
                 >
                   Sign In
                 </button>
               </>
             )}
           </nav>
         </div>
       </div>
     </header>
     <AuthenticateModal
       isOpen={showAuthModal}
       onClose={() => setShowAuthModal(false)}
       initialMode={authMode}
     />
   </>
 );
};

export default Header;