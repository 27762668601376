import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { signIn, signup } from '../../store/actions/userActions';
import { 
  HiEnvelope, 
  HiLockClosed, 
  HiUserCircle,
  HiArrowRight,
  HiExclamationCircle,
  HiXMark
} from "react-icons/hi2";
import authenticateService from '../../services/authenticateService';

const JoinCommunityModal = ({ communityId, communityName, isOpen, onClose }) => {
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(false);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    name: ''
  });

  const [touched, setTouched] = useState({
    email: false,
    password: false,
    name: false
  });

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const getValidationError = (field) => {
    if (!touched[field]) return '';
    
    switch (field) {
      case 'email':
        if (!formData.email) return 'Email is required';
        if (!validateEmail(formData.email)) return 'Invalid email format';
        return '';
      case 'password':
        if (!formData.password) return 'Password is required';
        if (formData.password.length < 6) return 'Password must be at least 6 characters';
        return '';
      case 'name':
        if (!isLogin && !formData.name) return 'Name is required';
        return '';
      default:
        return '';
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError('');
  };

  const handleBlur = (field) => {
    setTouched(prev => ({
      ...prev,
      [field]: true
    }));
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const pendingCommunityId = localStorage.getItem('pendingCommunityId');
      
      if (isLogin) {
        const loginData = { email: formData.email, password: formData.password };
        const response = await dispatch(signIn(loginData));
        if (pendingCommunityId) {
          await authenticateService.joinCommunity(pendingCommunityId, response.user_id);
        }
      } else {
        const signupData = {
          fullName: formData.name,
          email: formData.email,
          password: formData.password,
          parish: communityName,
          subscriptionType: 'regular',
          subscription_status: 'regular',
          communityId: pendingCommunityId,
          initialAssessment: []
        };
        
        const response = await dispatch(signup(signupData));
        
        if (response?.user_id && pendingCommunityId) {
          await authenticateService.joinAfterSignup(pendingCommunityId, response.user_id);
          window.location.href = `/dashboard`;
        }
      }
      
      localStorage.removeItem('pendingCommunityId');
      onClose();
    } catch (err) {
      setError(err.response?.data?.error || err.message);
    } finally {
      setLoading(false);
    }
  };



  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center justify-center">
      <div className="relative mx-auto p-5 w-full max-w-md">
        <div className="relative bg-white rounded-lg shadow-xl">
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
          >
            <HiXMark className="h-6 w-6" />
          </button>

          <div className="px-6 py-8">
            <div className="text-center mb-8">
              <h2 className="text-3xl font-bold text-gray-900">
                Join {communityName}
              </h2>
              <p className="mt-2 text-gray-600">
                Begin your ministry's Examen.
              </p>
            </div>

            <button
              onClick={() => setIsLogin(!isLogin)}
              className="w-full mb-6 text-sm font-medium text-blue-600 hover:text-blue-500"
            >
              {!isLogin ? 'Already have an account? Sign in' : 'Need an account? Sign up'}
            </button>

            <form className="space-y-6" onSubmit={handleSubmit}>
              {!isLogin && (
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Full Name
                  </label>
                  <div className="mt-1 relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                      <HiUserCircle className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      onBlur={() => handleBlur('name')}
                      className="pl-10 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                      placeholder="John Smith"
                    />
                  </div>
                  {getValidationError('name') && (
                    <p className="mt-2 text-sm text-red-600">{getValidationError('name')}</p>
                  )}
                </div>
              )}

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1 relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                    <HiEnvelope className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    onBlur={() => handleBlur('email')}
                    className="pl-10 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    placeholder="you@example.com"
                  />
                </div>
                {getValidationError('email') && (
                  <p className="mt-2 text-sm text-red-600">{getValidationError('email')}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1 relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
                    <HiLockClosed className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    onBlur={() => handleBlur('password')}
                    className="pl-10 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                    placeholder="••••••••"
                  />
                </div>
                {getValidationError('password') && (
                  <p className="mt-2 text-sm text-red-600">{getValidationError('password')}</p>
                )}
              </div>

              {error && (
                <div className="rounded-md bg-red-50 p-4">
                  <div className="flex">
                    <HiExclamationCircle className="h-5 w-5 text-red-400" />
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">{error}</h3>
                    </div>
                  </div>
                </div>
              )}

              <button
                type="submit"
                disabled={loading}
                className="w-full flex justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                {loading ? (
                  <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                ) : (
                  <>
                    {isLogin ? 'Sign in & Join' : 'Sign up & Join'}
                    <HiArrowRight className="ml-2 -mr-1 w-4 h-4" />
                  </>
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinCommunityModal;