// services/group_service.js
import axios from 'axios';
import { API_ENDPOINTS } from "../config/api";


export const groupService = {
  // Create a new group
  createGroup: async (groupData) => {
    try {
      const triviaConfig = {
        question_count: groupData.questionCount || 10,
        difficulty: groupData.difficulty || 'medium',
        show_source: true,
        track_progress: true,
        question_type: 'multiple_choice',
        type_distribution: {
          multiple_choice: groupData.multipleChoiceCount || 6,
          fill_in_blank: groupData.fillInBlankCount || 2,
          user_input: groupData.userInputCount || 2
        },
        topics: groupData.topics || ['Catechesis', 'Scripture', 'Moral Life', 'Saints']
      };

      const response = await axios.post(API_ENDPOINTS.CREATE_GROUP, {
        name: groupData.name,
        description: groupData.description,
        adminId: groupData.adminId,
        communityId: groupData.communityId,  // Added this
        is_public: false,
        type: 'trivia',
        trivia_enabled: true,
        trivia_config: triviaConfig,
        trivia_question_count: groupData.questionCount || 10,
        special_instructions: groupData.instructions || '',
        difficulty: groupData.difficulty || 'medium',
        questionCount: groupData.questionCount || 10,
        multipleChoiceCount: groupData.multipleChoiceCount || 6,
        fillInBlankCount: groupData.fillInBlankCount || 2,
        userInputCount: groupData.userInputCount || 2,
        topics: groupData.topics || ['Catechesis', 'Scripture', 'Moral Life', 'Saints']
      });
      return response.data;
    } catch (error) {
      console.error('Error creating group:', error);
      throw error;
    }
  },

  setPrimaryGroup: async (communityId, groupId) => {
    try {
      const response = await axios.put(
        API_ENDPOINTS.SET_PRIMARY_GROUP
          .replace(':communityId', communityId)
          .replace(':groupId', groupId)
      );
      return response.data;
    } catch (error) {
      console.error('Error setting primary group:', error);
      throw error;
    }
  },

  // Add group to community
  addGroupToCommunity: async (communityId, groupId, userId) => {
    try {
      const response = await axios.post(
        API_ENDPOINTS.ADD_GROUP_TO_COMMUNITY
          .replace(':communityId', communityId)
          .replace(':groupId', groupId),
        { user_id: userId }
      );
      return response.data;
    } catch (error) {
      console.error('Error adding group to community:', error);
      throw error;
    }
  },

  // Remove group from community
  removeGroupFromCommunity: async (communityId, groupId, userId) => {
    try {
      const response = await axios.delete(
        API_ENDPOINTS.REMOVE_GROUP_FROM_COMMUNITY
          .replace(':communityId', communityId)
          .replace(':groupId', groupId),
        { data: { user_id: userId } }
      );
      return response.data;
    } catch (error) {
      console.error('Error removing group from community:', error);
      throw error;
    }
  },

  // Get admin's groups
  getAdminGroups: async (userId, communityId) => {
    try {
      const response = await axios.get(
        API_ENDPOINTS.GET_ADMIN_GROUPS.replace(':userId', userId),
        { params: { community_id: communityId } }
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching admin groups:', error);
      throw error;
    }
  },

  // Get community groups
  getCommunityGroups: async (communityId, userId) => {
    try {
      const response = await axios.get(
        API_ENDPOINTS.GET_COMMUNITY_GROUPS.replace(':communityId', communityId),
        { params: { user_id: userId } }
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching community groups:', error);
      throw error;
    }
  },

  // Get group details
  getGroupDetails: async (groupId) => {
    try {
      const response = await axios.get(
        API_ENDPOINTS.GET_GROUP_DETAILS.replace(':groupId', groupId)
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching group details:', error);
      throw error;
    }
  },

  deleteGroup: async (groupId, userId) => {
    try {
      const response = await axios.delete(
        API_ENDPOINTS.DELETE_GROUP.replace(':groupId', groupId),
        { data: { userId } }
      );
      return response.data;
    } catch (error) {
      console.error('Error deleting group:', error);
      throw error;
    }
  },



  updateGroup: async (groupId, groupData) => {
    try {
      const triviaConfig = {
        question_count: groupData.questionCount || 10,
        difficulty: groupData.difficulty || 'medium',
        show_source: true,
        track_progress: true,
        question_type: 'multiple_choice',
        type_distribution: {
          multiple_choice: groupData.multipleChoiceCount || 6,
          fill_in_blank: groupData.fillInBlankCount || 2,
          user_input: groupData.userInputCount || 2
        },
        topics: groupData.topics || ['Catechesis', 'Scripture', 'Moral Life', 'Saints']
      };

      const response = await axios.put(
        API_ENDPOINTS.UPDATE_GROUP.replace(':groupId', groupId),
        {
          userId: groupData.userId, // Add this
          name: groupData.name,
          description: groupData.description,
          questionCount: groupData.questionCount || 10,
          difficulty: groupData.difficulty || 'medium',
          multipleChoiceCount: groupData.multipleChoiceCount || 6,
          fillInBlankCount: groupData.fillInBlankCount || 2,
          userInputCount: groupData.userInputCount || 2,
          instructions: groupData.instructions || '',
          topics: groupData.topics || ['Catechesis', 'Scripture', 'Moral Life', 'Saints'],
          trivia_config: triviaConfig,
          trivia_question_count: groupData.questionCount || 10,
          special_instructions: groupData.instructions || '',
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error updating group:', error);
      throw error;
    }
  }
};

export default groupService;