import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { HiPlus, HiUserGroup, HiUser, HiEye, HiPencil, HiTrash,HiStar } from "react-icons/hi2";
import { HiDocumentText, HiBookOpen } from "react-icons/hi";
import { HiDocumentDuplicate } from "react-icons/hi2";
import InviteMemberModal from '../../components/InviteMemberModal/InviteMemberModal';
import { groupService } from '../../services/group_service';
import { useNavigate } from 'react-router-dom';
import SurveyConfig from '../../components/SurveyConfig/SurveyConfig';
import FeedbackModal from '../../components/FeedbackModal/FeedbackModal';
import DeleteConfirmModal from '../../components/DeleteConfirmModal/DeleteConfirmModal';
import AdminNav from '../../components/AdminNav/AdminNav';
import Header from '../../components/Header/Header';
import authenticateService from '../../services/authenticateService';


const AdminDashboard = () => {
  const { communityId } = useParams();
  const { userId } = useSelector((state) => state.user);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [currentView, setCurrentView] = useState('dashboard');
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [adminGroups, setAdminGroups] = useState([]);
  const [communityGroups, setCommunityGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const MAX_ASSESSMENTS = 3;
  const defaultTopics = ['Gospel Teachings', 'Catechism', 'Works of the Saints', 'Liturgy'];
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState(null);  
  const [activeTab, setActiveTab] = useState('assessments');
  const [church, setChurch] = useState({ name: '', id: communityId });


  // Template assessments
  const templateAssessments = [

   {
     id: 'template-2',
     name: 'Bible Study Series',
     description: 'In-depth Scripture study with Catholic interpretation',
     questionCount: 10,
     difficulty: 'hard',
     topics: ['Catholic Biblical Studies'], 
     instructions: 'Deep theological examination of Scripture through Catholic lens, incorporating Church Fathers\' interpretations, Magisterial teachings, and historical-critical method with emphasis on spiritual and moral applications.'
   },

   {
     id: 'template-1',
     name: 'Religous Education Classes',
     description: 'Core Catholic beliefs and practices assessment for RCIA candidates',
     questionCount: 10,
     difficulty: 'medium',
     topics: ['RCIA Formation'],
     instructions: 'Guide candidates through foundational Catholic teachings focusing on sacramental preparation, understanding of Mass, prayer life development, and moral teachings in accordance with the Catechism.'
   },

   {
     id: 'template-3',
     name: 'Parish-Wide Theology',
     description: 'Complex theological topics and challenging Catholic teachings',
     questionCount: 10,
     difficulty: 'medium',
     topics: ['Advanced Catholic Theology'],
     instructions: 'Examine advanced theological concepts including bioethics, marriage theology, social teachings on controversial issues, and complex moral theology topics requiring careful understanding of Church doctrine and pastoral application.'
   }
  ];

  const [newGroup, setNewGroup] = useState({
    name: '',
    questionCount: 5,
    difficulty: 'medium',
    topics: [defaultTopics[Math.floor(Math.random() * defaultTopics.length)]],
    instructions: ''
  });

  // Add debug effect
  useEffect(() => {
    console.log('newGroup state updated:', newGroup);
  }, [newGroup]);

  const fetchGroups = async () => {
    try {
      setLoading(true);
      const [adminGroupsData, communityGroupsData] = await Promise.all([
        groupService.getAdminGroups(userId, communityId),
        groupService.getCommunityGroups(communityId, userId)
      ]);
      setAdminGroups(adminGroupsData);
      setCommunityGroups(communityGroupsData);
    } catch (err) {
      setError('Failed to fetch groups');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userId && communityId) {
      fetchGroups();
    }
  }, [userId, communityId]);


  useEffect(() => {
    const fetchCommunityInfo = async () => {
      try {
        const info = await authenticateService.getCommunityInfo(communityId);
        setChurch(prev => ({
          ...prev,
          name: info.name
        }));
      } catch (err) {
        console.error('Error fetching community info:', err);
      }
    };

    if (communityId) {
      fetchCommunityInfo();
    }
  }, [communityId]);



  const handleCreateGroup = async (groupData = null) => {
   try {
     if (adminGroups.length >= MAX_ASSESSMENTS) {
       setError('Maximum of 3 assessments allowed at once');
       return;
     }

     setLoading(true);
     console.log('newGroup:', newGroup);
     console.log('userId:', userId);
     console.log('communityId:', communityId);
     
     const dataToSubmit = groupData || {
       name: newGroup.name,
       adminId: userId,
       communityId: communityId,
       questionCount: newGroup.questionCount,
       difficulty: newGroup.difficulty,
       instructions: newGroup.instructions,
       topics: newGroup.topics,
       type: 'trivia'
     };

     console.log('Submitting data:', dataToSubmit);
     
     const response = await groupService.createGroup(dataToSubmit);
     
     if (response.success) {
       await fetchGroups();
       setSuccessMessage('Assessment created successfully!');
       setShowSuccessModal(true);
       setCurrentView('dashboard');  
       setActiveTab('assessments');
     }
   } catch (err) {
     setError('Failed to create group');
     console.error('Error in handleCreateGroup:', err);
   } finally {
     setLoading(false);
   }
  };

  const handleUseTemplate = (template) => {
      console.log("Template data:", template); // Add this
      const templateData = {
        name: template.name,
        adminId: userId,
        communityId: communityId,
        questionCount: template.questionCount, 
        difficulty: template.difficulty,
        topics: template.topics,
        instructions: template.instructions,
        type: 'trivia'
      };
      console.log("Template data being sent:", templateData); // Add this
      handleCreateGroup(templateData);
  };

  const handleViewStats = (group) => {
    navigate(`/stats/${communityId}/${group.id}`);
  };


  const handleEditGroup = async (group) => {
    setCurrentView('create-assessment');
    setSelectedGroup(group);
    
    setNewGroup({
      name: group.name || '',
      questionCount: group.trivia_question_count || 5,
      difficulty: group.difficulty || 'medium',
      topics: group.topics || [defaultTopics[Math.floor(Math.random() * defaultTopics.length)]],
      instructions: group.special_instructions || '',
      id: group.id,
      adminId: group.admin_id || userId
    });
  };

  const handleSetPrimary = async (group) => {
    try {
      setLoading(true);
      await groupService.setPrimaryGroup(communityId, group.id);
      await fetchGroups();
      setSuccessMessage('Primary assessment updated successfully!');
      setShowSuccessModal(true);
    } catch (err) {
      setError('Failed to set primary assessment');
    } finally {
      setLoading(false);
    }
  };




  // Add these handler functions
  const handleDeleteClick = (group) => {
    setGroupToDelete(group);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      setLoading(true);
      await groupService.deleteGroup(groupToDelete.id, userId);
      await fetchGroups();
      setSuccessMessage('Assessment deleted successfully!');
      setShowSuccessModal(true);
      setShowDeleteModal(false);
      setGroupToDelete(null);
    } catch (err) {
      setError('Failed to delete assessment');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };




  const handleUpdateGroup = async () => {
    try {
      setLoading(true);
      setError(null);

      if (!selectedGroup?.id) {
        throw new Error('No group selected for update');
      }

      const updateData = {
        userId: userId,
        name: newGroup.name,
        questionCount: parseInt(newGroup.questionCount),
        difficulty: newGroup.difficulty,
        topics: newGroup.topics,
        instructions: newGroup.instructions,
        adminId: userId
      };

      const response = await groupService.updateGroup(selectedGroup.id, updateData);
      
      if (response.success || response.message) {
        await fetchGroups();
        setSuccessMessage('Assessment updated successfully!');
        setShowSuccessModal(true);
      }

    } catch (err) {
      console.error('Error updating group:', err);
      setError(err.response?.data?.error || err.message || 'Failed to update group');
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = () => {
    setShowSuccessModal(false);
    setCurrentView('dashboard');
    setSelectedGroup(null);
    setNewGroup({});
  };





  const EmptyAssessments = () => (
    <div className="bg-gray-50 rounded-lg p-8 text-center">
      <HiDocumentText className="w-12 h-12 text-gray-400 mx-auto mb-4" />
      <h3 className="text-lg font-medium text-gray-900 mb-2">No Assessments Yet</h3>
      <p className="text-gray-600 mb-6">Get started by creating your first assessment or using a template</p>
      <div className="flex justify-center gap-4">
        <button
          onClick={() => setCurrentView('create-assessment')}
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Create Assessment
        </button>
        <button
          onClick={() => setActiveTab('templates')}
          className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
        >
          Browse Templates
        </button>
      </div>
    </div>
  );

  const handleRemoveGroupFromCommunity = async (groupId) => {
    try {
      setLoading(true);
      await groupService.removeGroupFromCommunity(communityId, groupId, userId);
      await fetchGroups();
    } catch (err) {
      setError('Failed to remove group from community');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };


  const TemplateSection = () => (
    <div className="grid md:grid-cols-2 gap-6">
      {templateAssessments.map((template) => (
        <div key={template.id} className="bg-white rounded-lg shadow p-6 border border-gray-200">
          <div className="mb-4">
            <h3 className="text-lg font-semibold">{template.name}</h3>
            <p className="text-gray-600">{template.description}</p>
          </div>
          
          <div className="space-y-3 mb-4">
            <div className="flex items-center gap-2 text-sm">
              <span className="text-gray-500">Questions:</span>
              <span>{template.questionCount}</span>
            </div>
            
            <div>
              <span className="text-sm text-gray-500">Topics:</span>
              <div className="flex flex-wrap gap-2 mt-1">
                {template.topics.map(topic => (
                  <span
                    key={topic}
                    className="px-2 py-1 bg-blue-50 text-blue-600 rounded-full text-xs"
                  >
                    {topic}
                  </span>
                ))}
              </div>
            </div>
          </div>

          <button
            onClick={() => handleUseTemplate(template)}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium hover:bg-gray-50 flex items-center justify-center gap-2"
          >
            <HiDocumentDuplicate className="w-4 h-4" />
            Use Template
          </button>
        </div>
      ))}
    </div>
  );


  return (
   <div className="min-h-screen bg-gray-50">
     <Header />
     <main className="w-full">
       <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
         <div className="space-y-6">
           {/* Header Section */}
           <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-4">
             <div>
               <h1 className="text-2xl font-bold text-gray-900">{church.name}</h1>
               <p className="text-gray-600">Faith Assessment Dashboard</p>
             </div>

             <div className="flex justify-end gap-4">
               <button
                 onClick={() => navigate(`/dashboard/${userId}`)}
                 className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-50 rounded-lg hover:bg-gray-100 border border-gray-200 transition-colors"
               >
                 <HiUser className="w-4 h-4 mr-2" />
                 Member Dashboard
               </button>
               <button
                 onClick={() => setShowInviteModal(true)}
                 className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-50 rounded-lg hover:bg-gray-100 border border-gray-200 transition-colors"
               >
                 <HiUserGroup className="w-4 h-4 mr-2" />
                 Invite Others
               </button>
             </div>

             {/* Tabs */}
             <div className="flex space-x-1 bg-gray-100 p-1 rounded-lg self-start">
               {['assessments', 'templates'].map((tab) => (
                 <button
                   key={tab}
                   onClick={() => setActiveTab(tab)}
                   className={`px-4 py-2 rounded-md text-sm font-medium transition-colors ${
                     activeTab === tab
                       ? 'bg-white text-gray-900 shadow'
                       : 'text-gray-500 hover:text-gray-900'
                   }`}
                 >
                   {tab.charAt(0).toUpperCase() + tab.slice(1)}
                 </button>
               ))}
             </div>
           </div>

           <AdminNav communityId={communityId} />

           {/* Assessment Tab Content */}
           {activeTab === 'assessments' && (
             <div className="max-w-7xl mx-auto space-y-6">
               <div className="flex justify-end">
                 <button
                   onClick={() => setCurrentView('create-assessment')}
                   disabled={adminGroups.length >= MAX_ASSESSMENTS}
                   className={`inline-flex items-center px-4 py-2 rounded-lg transition-colors ${
                     adminGroups.length >= MAX_ASSESSMENTS 
                       ? 'bg-gray-300 cursor-not-allowed' 
                       : 'bg-blue-600 hover:bg-blue-700 text-white shadow-sm'
                   }`}
                 >
                   <HiPlus className="w-4 h-4 mr-2" />
                   Create New Assessment
                   {adminGroups.length >= MAX_ASSESSMENTS && 
                     <span className="ml-2 text-sm">(Max limit reached)</span>
                   }
                 </button>
               </div>

               {currentView === 'create-assessment' ? (
                 <SurveyConfig 
                   newGroup={newGroup}
                   setNewGroup={setNewGroup}
                   setCurrentView={setCurrentView}
                   handleCreateGroup={handleCreateGroup}
                   handleUpdateGroup={handleUpdateGroup}
                   selectedGroup={selectedGroup}
                 />
               ) : adminGroups.length === 0 ? (
                 <EmptyAssessments />
               ) : (
                 <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                   {adminGroups.map((group) => (
                     <div 
                       key={group.id} 
                       className="bg-white rounded-lg shadow-sm border border-gray-200 p-4 sm:p-6 hover:shadow-md transition-shadow"
                     >
                       <div className="flex flex-col sm:flex-row sm:justify-between sm:items-start gap-4">
                         <div className="flex-1 min-w-0">
                           <h3 className="text-lg font-semibold text-gray-900 mb-1">{group.name}</h3>
                           <p className="text-gray-600 text-sm">{group.description}</p>
                         </div>

                         <div className="flex flex-wrap gap-2 sm:flex-nowrap">
                           {[
                             { icon: HiEye, onClick: () => handleViewStats(group), label: "View Stats" },
                             { icon: HiPencil, onClick: () => handleEditGroup(group), label: "Edit" },
                             { icon: HiStar, onClick: () => handleSetPrimary(group), label: "Set Primary", isPrimary: group.is_primary },
                             { icon: HiTrash, onClick: () => handleDeleteClick(group), label: "Delete", isDelete: true }
                           ].map((action, index) => (
                             <button 
                               key={index}
                               onClick={action.onClick}
                               className={`p-2 rounded-md transition-colors ${
                                 action.isPrimary 
                                   ? 'bg-green-100 text-green-600 hover:bg-green-200' 
                                   : action.isDelete
                                     ? 'text-red-600 hover:bg-red-50'
                                     : 'text-gray-600 hover:bg-gray-100'
                               }`}
                               title={action.label}
                             >
                               <action.icon className="w-4 h-4" />
                             </button>
                           ))}
                         </div>
                       </div>
                     </div>
                   ))}
                 </div>
               )}
             </div>
           )}

           {/* Templates Tab Content */}
           {activeTab === 'templates' && (
             <div className="max-w-7xl mx-auto space-y-6">
               <div className="bg-blue-50 p-4 rounded-lg border border-blue-100">
                 <h2 className="text-lg font-semibold text-gray-900 mb-2">Pre-made Templates</h2>
                 <p className="text-gray-600">
                   Choose from our curated templates to quickly create assessments for your community.
                   All templates can be customized after creation.
                 </p>
               </div>
               <TemplateSection />
             </div>
           )}

           {/* Settings Tab Content */}
           {activeTab === 'settings' && (
             <div className="max-w-7xl mx-auto space-y-6">
               <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-4 sm:p-6">
                 <h2 className="text-lg font-semibold text-gray-900 mb-4">Parish Settings</h2>
                 <div className="space-y-4 max-w-xl">
                   <div>
                     <label className="block text-sm font-medium text-gray-700 mb-1">
                       Parish Name
                     </label>
                     <input
                       type="text"
                       value={church.name}
                       onChange={(e) => setChurch({ ...church, name: e.target.value })}
                       className="w-full rounded-lg border border-gray-300 px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                     />
                   </div>
                   <button
                     onClick={() => setShowInviteModal(true)}
                     className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 shadow-sm transition-colors"
                   >
                     <HiPlus className="w-4 h-4 mr-2" />
                     Invite Members
                   </button>
                 </div>
               </div>
             </div>
           )}
         </div>

         {/* Modals */}
         {showInviteModal && (
           <InviteMemberModal
             communityId={communityId}
             userId={userId}
             onClose={() => setShowInviteModal(false)}
           />
         )}

         <FeedbackModal 
           isOpen={showSuccessModal}
           onClose={handleModalClose}
           message={successMessage}
         />

         <DeleteConfirmModal 
           isOpen={showDeleteModal}
           onClose={() => {
             setShowDeleteModal(false);
             setGroupToDelete(null);
           }}
           onConfirm={handleDeleteConfirm}
           title={groupToDelete?.name || 'Assessment'}
         />
       </div>
     </main>
   </div>
  );

};

export default AdminDashboard;

