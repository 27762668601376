import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import authenticateService from '../../services/authenticateService';
import { signIn, signup } from '../../store/actions/userActions'; // Changed from redux/actions to store/actions
import { 
  HiHome, 
  HiEnvelope, 
  HiLockClosed, 
  HiUserCircle,
  HiArrowRight,
  HiExclamationCircle,
  HiBuildingOffice2,
  HiXMark
} from "react-icons/hi2";

const AuthenticateModal = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    name: '',
    parish: ''
  });

  const [touched, setTouched] = useState({
    email: false,
    password: false,
    name: false,
    parish: false
  });

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const getValidationError = (field) => {
    if (!touched[field]) return '';
    
    switch (field) {
      case 'email':
        if (!formData.email) return 'Email is required';
        if (!validateEmail(formData.email)) return 'Invalid email format';
        return '';
      case 'password':
        if (!formData.password) return 'Password is required';
        if (formData.password.length < 6) return 'Password must be at least 6 characters';
        return '';
      case 'name':
        if (!isLogin && !formData.name) return 'Name is required';
        return '';
      case 'parish':
        if (!isLogin && !formData.parish) return 'Ministry or Parish name is required';
        return '';
      default:
        return '';
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError('');
  };

  const handleBlur = (field) => {
    setTouched(prev => ({
      ...prev,
      [field]: true
    }));
  };

  const toggleMode = () => {
    setIsLogin(!isLogin);
    setError('');
    setFormData({
      email: '',
      password: '',
      name: '',
      parish: ''
    });
    setTouched({
      email: false,
      password: false,
      name: false,
      parish: false
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      if (isLogin) {
        const loginData = {
          email: formData.email,
          password: formData.password
        };
        
        const response = await dispatch(signIn(loginData));
        if (response) {
          onClose();
          // Update this to navigate to their community admin dashboard
          if (response.community_id) {
            navigate(`/admin/${response.community_id}`);
          } else {
            navigate('/dashboard');
          }
        }
      } else {
        const signupData = {
          fullName: formData.name,
          email: formData.email,
          password: formData.password,
          parish: formData.parish,
          subscriptionType: 'regular',
          subscription_status: 'regular'
        };
        
        const response = await dispatch(signup(signupData));
        if (response && response.community_id) {
          onClose();
          navigate(`/admin/${response.community_id}`);
        }
      }
    } catch (err) {
      setError(err.response?.data?.error || err.message);
    } finally {
      setLoading(false);
    }
  };




  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center justify-center">
      <div className="relative mx-auto p-5 w-full max-w-md">
        <div className="relative bg-white rounded-lg shadow-xl">
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
          >
            <HiXMark className="h-6 w-6" />
          </button>

          <div className="px-6 py-8">
            <div className="flex justify-center">
              <HiBuildingOffice2 className="w-12 h-12 text-blue-600" />
            </div>
            <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
              {isLogin ? 'Sign in to your account' : 'Create your account'}
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              {isLogin ? "Don't have an account? " : "Already have an account? "}
              <button
                onClick={toggleMode}
                className="font-medium text-blue-600 hover:text-blue-500"
              >
                {isLogin ? 'Sign up' : 'Sign in'}
              </button>
            </p>

            <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
              {!isLogin && (
                <>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Full Name
                    </label>
                    <div className="mt-1 relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <HiUserCircle className="h-5 w-5 text-gray-400" />
                      </div>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        onBlur={() => handleBlur('name')}
                        className={`appearance-none block w-full pl-10 pr-3 py-2 border ${
                          getValidationError('name') ? 'border-red-300' : 'border-gray-300'
                        } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                        placeholder="John Smith"
                      />
                      {getValidationError('name') && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <HiExclamationCircle className="h-5 w-5 text-red-500" />
                        </div>
                      )}
                    </div>
                    {getValidationError('name') && (
                      <p className="mt-2 text-sm text-red-600">
                        {getValidationError('name')}
                      </p>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Ministry or Parish Name
                    </label>
                    <div className="mt-1 relative">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <HiHome className="h-5 w-5 text-gray-400" />
                      </div>
                      <input
                        type="text"
                        name="parish"
                        value={formData.parish}
                        onChange={handleChange}
                        onBlur={() => handleBlur('parish')}
                        className={`appearance-none block w-full pl-10 pr-3 py-2 border ${
                          getValidationError('parish') ? 'border-red-300' : 'border-gray-300'
                        } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                        placeholder="Enter your ministry or parish name"
                      />
                      {getValidationError('parish') && (
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                          <HiExclamationCircle className="h-5 w-5 text-red-500" />
                        </div>
                      )}
                    </div>
                    {getValidationError('parish') && (
                      <p className="mt-2 text-sm text-red-600">
                        {getValidationError('parish')}
                      </p>
                    )}
                  </div>
                </>
              )}

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1 relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <HiEnvelope className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    onBlur={() => handleBlur('email')}
                    className={`appearance-none block w-full pl-10 pr-3 py-2 border ${
                      getValidationError('email') ? 'border-red-300' : 'border-gray-300'
                    } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                    placeholder="you@example.com"
                  />
                  {getValidationError('email') && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <HiExclamationCircle className="h-5 w-5 text-red-500" />
                    </div>
                  )}
                </div>
                {getValidationError('email') && (
                  <p className="mt-2 text-sm text-red-600">
                    {getValidationError('email')}
                  </p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Password
                </label>
                <div className="mt-1 relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <HiLockClosed className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    onBlur={() => handleBlur('password')}
                    className={`appearance-none block w-full pl-10 pr-3 py-2 border ${
                      getValidationError('password') ? 'border-red-300' : 'border-gray-300'
                    } rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500`}
                    placeholder="••••••••"
                  />
                  {getValidationError('password') && (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <HiExclamationCircle className="h-5 w-5 text-red-500" />
                    </div>
                  )}
                </div>
                {getValidationError('password') && (
                  <p className="mt-2 text-sm text-red-600">
                    {getValidationError('password')}
                  </p>
                )}
              </div>

              {error && (
                <div className="rounded-md bg-red-50 p-4">
                  <div className="flex">
                    <HiExclamationCircle className="h-5 w-5 text-red-400" />
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">
                        {error}
                      </h3>
                    </div>
                  </div>
                </div>
              )}

              <div>
                <button
                  type="submit"
                  disabled={loading}
                  className={`w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                    loading ? 'opacity-75 cursor-not-allowed' : ''
                  }`}
                >
                  {loading ? (
                    <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                  ) : (
                    <>
                      {isLogin ? 'Sign in' : 'Create account'}
                      <HiArrowRight className="ml-2 -mr-1 w-4 h-4" />
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthenticateModal;