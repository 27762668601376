// services/stats_service.js
import axios from 'axios';
import { API_ENDPOINTS } from "../config/api";

// Helper function to replace URL parameters
const replaceUrlParams = (url, params) => {
  let finalUrl = url;
  Object.keys(params).forEach(key => {
    finalUrl = finalUrl.replace(`:${key}`, params[key]);
  });
  return finalUrl;
};

/**
 * Get aggregate statistics for a group/mode
 * @param {number} groupId - The group/mode ID
 * @param {number} communityId - The community ID
 * @returns {Promise} Group statistics including completion rates, topic performance, etc.
 */
export const getGroupAggregateStats = async (groupId, communityId) => {
  try {
    const url = replaceUrlParams(API_ENDPOINTS.GET_GROUP_AGGREGATE_STATS, { groupId });
    const response = await axios.get(url, {
      params: { community_id: communityId }
    });

    if (response.status === 200) {
      return response.data;
    }
    throw new Error('Failed to fetch group statistics');
  } catch (error) {
    console.error('Error fetching group statistics:', error);
    throw error;
  }
};


export const getPrimaryAssessmentStats = async (groupId, communityId, userId) => {
  try {
    const url = replaceUrlParams(API_ENDPOINTS.GET_PRIMARY_STATS, { groupId });
    const response = await axios.get(url, {
      params: { community_id: communityId, user_id: userId }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching primary stats:', error);
    throw error;
  }
};

/**
 * Get list of participants and their basic stats
 * @param {number} groupId - The group/mode ID
 * @param {number} communityId - The community ID
 * @returns {Promise} List of participants with their basic performance metrics
 */
export const getGroupParticipants = async (groupId, communityId) => {
  try {
    const url = replaceUrlParams(API_ENDPOINTS.GET_GROUP_PARTICIPANTS, { groupId });
    const response = await axios.get(url, {
      params: { community_id: communityId }
    });

    if (response.status === 200) {
      return response.data;
    }
    throw new Error('Failed to fetch participants');
  } catch (error) {
    console.error('Error fetching participants:', error);
    throw error;
  }
};

/**
 * Get detailed statistics for a specific user in a group
 * @param {number} groupId - The group/mode ID
 * @param {number} userId - The user ID
 * @param {number} communityId - The community ID
 * @returns {Promise} Detailed user statistics including question-by-question breakdown
 */
export const getUserDetailedStats = async (groupId, userId, communityId) => {
  try {
    const url = replaceUrlParams(API_ENDPOINTS.GET_USER_DETAILED_STATS, { 
      groupId,
      userId 
    });
    const response = await axios.get(url, {
      params: { community_id: communityId }
    });

    if (response.status === 200) {
      return response.data;
    }
    throw new Error('Failed to fetch user statistics');
  } catch (error) {
    console.error('Error fetching user statistics:', error);
    throw error;
  }
};

/**
 * Helper function to calculate percentage
 * @param {number} value - The current value
 * @param {number} total - The total possible value
 * @returns {number} Rounded percentage
 */
export const calculatePercentage = (value, total) => {
  if (!total) return 0;
  return Math.round((value / total) * 100);
};

/**
 * Helper function to format score for display
 * @param {number} score - The score to format
 * @returns {string} Formatted score with % symbol
 */
export const formatScore = (score) => {
  return `${Math.round(score)}%`;
};

/**
 * Helper function to get color class based on score
 * @param {number} score - The score to evaluate
 * @returns {string} Tailwind CSS color class
 */
export const getScoreColorClass = (score) => {
  if (score >= 90) return 'text-green-600';
  if (score >= 75) return 'text-blue-600';
  if (score >= 60) return 'text-yellow-600';
  return 'text-red-600';
};

/**
 * Helper function to format difficulty level for display
 * @param {string} difficulty - The difficulty level
 * @returns {string} Formatted difficulty with proper capitalization
 */
export const formatDifficulty = (difficulty) => {
  if (!difficulty) return 'N/A';
  return difficulty.charAt(0).toUpperCase() + difficulty.slice(1);
};

export default {
  getGroupAggregateStats,
  getGroupParticipants,
  getUserDetailedStats,
  calculatePercentage,
  formatScore,
  getScoreColorClass,
  formatDifficulty
};