import React from 'react';
import { HiCheckCircle } from 'react-icons/hi2';

const FeedbackModal = ({ isOpen, onClose, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4 shadow-xl">
        <div className="text-center">
          <HiCheckCircle className="mx-auto h-12 w-12 text-blue-600" />
          <h3 className="mt-4 text-lg font-semibold text-gray-900">
            Success!
          </h3>
          <p className="mt-2 text-gray-600">
            {message}
          </p>
          <button
            onClick={onClose}
            className="mt-6 w-full inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeedbackModal;