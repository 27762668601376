import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_ENDPOINTS } from '../../config/api';

const AdminNav = ({ communityId }) => {
  const { userId } = useSelector((state) => state.user);
  const [isAdmin, setIsAdmin] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAdmin = async () => {
      try {
        const response = await axios.get(
          `${API_ENDPOINTS.CHECK_ADMIN_STATUS}?communityId=${communityId}&user_id=${userId}`
        );
        setIsAdmin(response.data.is_admin);
      } catch (err) {
        console.error('Error checking admin status:', err);
      }
    };

    if (communityId && userId) {
      checkAdmin();
    }
  }, [communityId, userId]);

  if (!isAdmin) return null;

  const handleDashboardSwitch = () => {
    if (window.location.pathname.includes('/admin')) {
      navigate(`/dashboard/${userId}`);
    } else {
      navigate(`/admin/${communityId}`);
    }
  };

  return (
    <div className="flex justify-end mb-4">
      <button
        onClick={handleDashboardSwitch}
        className="inline-flex items-center px-4 py-2 text-sm font-medium text-blue-600 hover:text-blue-800 transition-colors"
      >
        {window.location.pathname.includes('/admin') 
          ? 'View User Dashboard' 
          : 'Go to Admin Dashboard'}
      </button>
    </div>
  );
};

export default AdminNav;