import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { HiXMark, HiPlus, HiBuildingOffice2, HiInformationCircle } from "react-icons/hi2";
import axios from 'axios';
import { API_ENDPOINTS } from '../../config/api';
import AuthenticateModal from '../AuthenticateModal/AuthenticateModal';

const CreateCommunityModal = ({ isOpen, onClose, onSuccess }) => {
 const { userId, tempUserId } = useSelector((state) => state.user);
 const effectiveUserId = userId || tempUserId;
 
 const [loading, setLoading] = useState(false);
 const [error, setError] = useState('');
 const [showAuthModal, setShowAuthModal] = useState(false);
 const [formData, setFormData] = useState({
   name: '',
   description: ''
 });

 const handleSubmit = async (e) => {
   e.preventDefault();
   setLoading(true);
   try {
     const payload = {
       name: formData.name,
       description: formData.description,
       user_id: effectiveUserId  // Changed from userId to user_id to match backend
     };
     const response = await axios.post(API_ENDPOINTS.CREATE_COMMUNITY, payload);
     onSuccess(response.data);
     onClose();
   } catch (err) {
     setError(err.response?.data?.error || 'Failed to create community');
   } finally {
     setLoading(false);
   }
 };

 if (!isOpen) return null;
 
 if (effectiveUserId >= 10000) {
   return <AuthenticateModal isOpen={isOpen} onClose={onClose} />;
 }

 return (
   <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
     <div className="bg-white rounded-lg p-6 w-full max-w-md">
       <div className="flex justify-between items-center mb-6">
         <div className="flex items-center">
           <HiBuildingOffice2 className="w-6 h-6 text-blue-600 mr-2" />
           <h2 className="text-xl font-bold">Create New Community</h2>
         </div>
         <button onClick={onClose}>
           <HiXMark className="w-6 h-6 text-gray-400 hover:text-gray-500" />
         </button>
       </div>

       <div className="bg-blue-50 border border-blue-100 rounded-lg p-4 mb-6">
         <div className="flex items-start">
           <HiInformationCircle className="w-5 h-5 text-blue-600 mt-0.5 mr-2 flex-shrink-0" />
           <div>
             <h3 className="text-sm font-medium text-blue-800 mb-1">Create a Free Account to:</h3>
             <ul className="text-sm text-blue-700 space-y-1">
               <li>• Access your communities across all sessions</li>
               <li>• View detailed Examen statistics and user completion metrics</li>
               <li>• Track aggregate progress and participation data</li>
             </ul>
           </div>
         </div>
       </div>

       <form onSubmit={handleSubmit}>
         <div className="space-y-4">
           <div>
             <label className="block text-sm font-medium text-gray-700 mb-1">
               Community Name
             </label>
             <input
               type="text"
               value={formData.name}
               onChange={(e) => setFormData({...formData, name: e.target.value})}
               className="w-full rounded-md border border-gray-300 px-3 py-2"
               required
             />
           </div>
           <div>
             <label className="block text-sm font-medium text-gray-700 mb-1">
               Description
             </label>
             <textarea
               value={formData.description}
               onChange={(e) => setFormData({...formData, description: e.target.value})}
               className="w-full rounded-md border border-gray-300 px-3 py-2"
               rows={3}
             />
           </div>
         </div>
         {error && (
           <div className="mt-4 text-sm text-red-600">
             {error}
           </div>
         )}
         <div className="mt-6">
           <button
             type="submit"
             disabled={loading}
             className="w-full flex justify-center items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
           >
             {loading ? (
               <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
             ) : (
               <>
                 <HiPlus className="w-5 h-5 mr-2" />
                 Create Community
               </>
             )}
           </button>
         </div>
       </form>
     </div>
   </div>
 );
};

export default CreateCommunityModal;