// Manually set this to 'development' or 'production'
const CURRENT_ENV = 'production';

const API_BASE_URLS = {
  development: {
    5000: 'http://localhost:5000',
    5002: 'http://localhost:5002',
    5064: 'http://localhost:5064'

  },


  production: {
    5000: 'https://examenai.com',
    5002: 'https://examenai.com',
    5064: 'https://examenai.com',

  }
};

const CURRENT_BASE_URLS = API_BASE_URLS[CURRENT_ENV];

export const API_ENDPOINTS = {

  GET_RTC_TOKEN: `${CURRENT_BASE_URLS[5002]}/api/get_token`, // Add this line
  STORE_CONTEXT: `${CURRENT_BASE_URLS[5002]}/store-context`,
  GET_TRIVIA_STATS: `${CURRENT_BASE_URLS[5002]}/trivia-stats`,
  TRIVIA_PROGRESS: `${CURRENT_BASE_URLS[5002]}/api/trivia-progress`,
  TEXT_SOCKET: `wss://examenai.com/text-socket`,


  USER_INFORMATION: `${CURRENT_BASE_URLS[5000]}/user-information`,
  LOGIN: `${CURRENT_BASE_URLS[5000]}/login`,
  GET_USER_ID: `${CURRENT_BASE_URLS[5000]}/get_user_id`,
  SIGNUP: `${CURRENT_BASE_URLS[5000]}/register`,


  GET_PUBLIC_COMMUNITIES: `${CURRENT_BASE_URLS[5064]}/api/communities/public`,
  CREATE_COMMUNITY: `${CURRENT_BASE_URLS[5064]}/api/communities/create`,
  JOIN_COMMUNITY: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId/join`,


  // New endpoints
  GET_COMMUNITY_DETAILS: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId`,
  JOIN_GROUP: `${CURRENT_BASE_URLS[5064]}/api/modes/:groupId/join`,
  GET_COMMUNITY_MEMBERS: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId/members`,

  // New community invitation endpoints
  INVITE_TO_COMMUNITY: `${CURRENT_BASE_URLS[5064]}/api/faith_journey/communities/:communityId/invite`,
  JOIN_COMMUNITY_AFTER_SIGNUP: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId/join-after-signup`,

  GET_USER_MEMBER_COMMUNITIES: `${CURRENT_BASE_URLS[5064]}/api/users/:userId/member-communities`,

  MINISTRY_REQUEST: `${CURRENT_BASE_URLS[5064]}/api/communities/ministry-request`,


  // Core endpoints currently in use
  GET_COMMUNITY_GROUPS: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId/groups`,
  GET_ADMIN_GROUPS: `${CURRENT_BASE_URLS[5064]}/api/users/:userId/admin-groups`,
  CREATE_GROUP: `${CURRENT_BASE_URLS[5064]}/api/communities/groups/create`,
  UPDATE_GROUP: `${CURRENT_BASE_URLS[5064]}/api/communities/groups/:groupId/update`,
  DELETE_GROUP: `${CURRENT_BASE_URLS[5064]}/api/communities/groups/delete/:groupId`,
  ADD_GROUP_TO_COMMUNITY: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId/groups/:groupId`,
  REMOVE_GROUP_FROM_COMMUNITY: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId/groups/:groupId`,

  // Add these with your other endpoints in API_ENDPOINTS
  GET_USER_ASSESSMENT_HISTORY: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId/user-history`,

  // Add to your API_ENDPOINTS
  GET_GROUP_AGGREGATE_STATS: `${CURRENT_BASE_URLS[5064]}/api/communities/stats/groups/:groupId/aggregate`,
  GET_GROUP_PARTICIPANTS: `${CURRENT_BASE_URLS[5064]}/api/communities/stats/groups/:groupId/participants`,
  GET_USER_DETAILED_STATS: `${CURRENT_BASE_URLS[5064]}/api/communities/stats/groups/:groupId/users/:userId`,

  GET_PRIMARY_STATS: `${CURRENT_BASE_URLS[5064]}/api/communities/stats/primary/:groupId`,

  GET_INVITE_QR: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId/invite-qr`,
  JOIN_COMMUNITY: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId/join`,
  JOIN_AFTER_SIGNUP: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId/join-after-signup`,
  COMMUNITY_INFO: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId/info`,


  CREATE_TRIVIA_USER: `${CURRENT_BASE_URLS[5064]}/api/communities/trivia/users`,
  GET_TRIVIA_USER_NAME: `${CURRENT_BASE_URLS[5064]}/api/communities/trivia/users/:userId/name`,
  GET_USER_COMPLETED_SESSIONS: `${CURRENT_BASE_URLS[5064]}/api/communities/users/:userId/completed-sessions`,
  SET_PRIMARY_GROUP: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId/groups/:groupId/set-primary`,

  CHECK_ADMIN_STATUS: `${CURRENT_BASE_URLS[5064]}/api/communities/:communityId/check-admin`,
  GET_USER_ADMIN_COMMUNITY: `${CURRENT_BASE_URLS[5064]}/api/users/:userId/admin-community`,

  GET_CURRENT_ASSESSMENT: `${CURRENT_BASE_URLS[5064]}/api/communities/users/:userId/current-assessment`,
  GET_ADDITIONAL_ASSESSMENTS: `${CURRENT_BASE_URLS[5064]}/api/communities/users/:userId/additional-assessments`,

};